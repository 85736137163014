import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import EditableField from "../../../../EditableField/EditableField";
import {
  updateAthleteResult,
  updateEventAttribute,
} from "../../../../../data-store/actions/events-actions";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { DescargarCertificadoReg } from "../../../reports/atleteDetail/certificadoLogic";
import BluetoothManager from "../../../../../services/BluetoothManager";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";
import { getCheckChip } from "../../../../../data-store/actions/events-actions";
import CheckChip from "./CheckChip";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function CheckChipContainer(props) {
  const dispatch = useDispatch();
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const checkChip = useSelector((state) => state.events.checkChip);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );

  const classes = useStyles();
  const eventParticipantsList = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventParticipantsRequested = useSelector(
    (state) => state.registry.eventParticipantsRequested
  );
  const eventParticipantsEventIndex = useSelector(
    (state) => state.registry.eventParticipantsEventIndex
  );

  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);

  useEffect(() => {
    if (
      !eventParticipantsRequested ||
      eventParticipantsEventIndex !== "evento_" + eventDetail.iDEvento
    ) {
      dispatch(eventParticipants("evento_" + eventDetail.iDEvento));
    }
    dispatch(getCheckChip("evento_" + eventDetail.iDEvento));
  }, [eventSubEvents, eventSubEventsRegistry]);

  function getAdicionalAtributes(eventParticipantsData) {
    let attributes = {};

    Object.values(eventParticipantsData).map((a) => {
      console.log(a);
      Object.keys(a).map((b) => (attributes[b] = 0));
    });

    const keysToDelete = [
      "fechaRegistro",
      "mails",
      "monedaAUsar",
      "monedaBase",
      "order",
      "serverTime",
      "statusPago",
      "versionForm",
      "tipoRegistro",
      "usuarioRegistra",
    ];
    keysToDelete.forEach((key) => delete attributes[key]);

    return Object.keys(attributes);
  }

  function handleChange(data) {
    console.log("dataTIMING", data);
    // if (Object.keys(data)[0] === "to") {
    //   data = { ...data, recPos: true };
    // } ya no recalculo posiciones por que se hace en local
    console.log("data", data);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "configuracion/timingConfig/",
        data
      )
    );
  }

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid container direction="row" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h3"} component="h2">
            Configuración vista Checa Chip
          </Typography>{" "}
          <Typography gutterBottom variant={"h5"} component="h2">
            Agrega una imagen de fonto y color de teto para personalizar la
            vista
          </Typography>{" "}
        </Grid>
        <Grid item xs={12} style={{ margin: "10px" }}>
          <EditableField
            fieldObject={{
              label: "Imagen para el fondo",
              errorMessage: "Campo Requerido",
              fieldName: "checkChipConfig/checkChipImage",
              type: "photo",
              defaultValue:
                eventConfiguration &&
                eventConfiguration.timingConfig &&
                eventConfiguration.timingConfig.checkChipConfig &&
                eventConfiguration.timingConfig.checkChipConfig.checkChipImage
                  ? eventConfiguration.timingConfig.checkChipConfig
                      .checkChipImage
                  : false,
              required: true,
              regex: "",
              textSize: "h6",
            }}
            onSubmit={handleChange}
          />
        </Grid>
        <Grid item xs={12} style={{ margin: "10px" }}>
          <EditableField
            fieldObject={{
              label: "Color del texto",
              errorMessage: "Campo Requerido",
              fieldName: "checkChipConfig/checkChipFontColor",
              type: "color",
              defaultValue:
                eventConfiguration &&
                eventConfiguration.timingConfig &&
                eventConfiguration.timingConfig.checkChipConfig &&
                eventConfiguration.timingConfig.checkChipConfig
                  .checkChipFontColor
                  ? eventConfiguration.timingConfig.checkChipConfig
                      .checkChipFontColor
                  : false,
              required: true,
              regex: "",
              textSize: "h6",
            }}
            onSubmit={handleChange}
          />
        </Grid>
        <Grid item xs={12} style={{ margin: "10px" }}>
          <EditableField
            fieldObject={{
              label: "Color del fondo",
              errorMessage: "Campo Requerido",
              fieldName: "checkChipConfig/checkChipBackgroundColor",
              type: "color",
              defaultValue:
                eventConfiguration &&
                eventConfiguration.timingConfig &&
                eventConfiguration.timingConfig.checkChipConfig &&
                eventConfiguration.timingConfig.checkChipConfig
                  .checkChipBackgroundColor
                  ? eventConfiguration.timingConfig.checkChipConfig
                      .checkChipBackgroundColor
                  : false,
              required: true,
              regex: "",
              textSize: "h6",
            }}
            onSubmit={handleChange}
          />
        </Grid>
        {/* <Grid item xs={12} style={{ margin: "10px" }}>
          <EditableField
            fieldObject={{
              label:
                "Posición horizontal del texto (0 izquierda, 1 centro, 2 derecha, admite decimales)",
              errorMessage: "Campo Requerido",
              fieldName: "checkChipConfig/checkChipPosition",
              type: "text",
              min: 0,
              defaultValue:
                eventConfiguration &&
                eventConfiguration.timingConfig &&
                eventConfiguration.timingConfig.checkChipConfig &&
                eventConfiguration.timingConfig.checkChipConfig
                  .checkChipPosition
                  ? eventConfiguration.timingConfig.checkChipConfig
                      .checkChipPosition
                  : 1,
              required: true,
              regex: "",
              textSize: "h6",
            }}
            onSubmit={handleChange}
          />
        </Grid> */}
        <Grid item xs={12} style={{ margin: "10px" }}>
          <EditableField
            fieldObject={{
              label:
                "Posición vertical del texto (0 arriba, 1 centro, 2 abajo, admite decimales)",
              errorMessage: "Campo Requerido",
              fieldName: "checkChipConfig/checkChipPositionVertical",
              type: "text",
              min: 0,
              defaultValue:
                eventConfiguration &&
                eventConfiguration.timingConfig &&
                eventConfiguration.timingConfig.checkChipConfig &&
                eventConfiguration.timingConfig.checkChipConfig
                  .checkChipPositionVertical
                  ? eventConfiguration.timingConfig.checkChipConfig
                      .checkChipPositionVertical
                  : 1,
              required: true,
              regex: "",
              textSize: "h6",
            }}
            onSubmit={handleChange}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} style={{ marginBottom: 200 }}>
        <SimpleDialogDemo
          buttonText={"ABRIR VISTA AL PÚBLICO"}
          fullScreen={true}
        >
          <CheckChip />
        </SimpleDialogDemo>
      </Grid>
    </Grid>
  );
}
