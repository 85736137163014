import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Avatar, Button, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createEventConfig from "../EventCreate/createEventconfig.json";
import { updateEventAttribute } from "../../../../../data-store/actions/events-actions";
import {
  generateVirtualEventsLink,
  sendAllMails,
} from "../../../../../data-store/actions/registry-actions";
import EditableField from "../../../../EditableField/EditableField";
import PhotoEditorToPrint from "../../../../Landing/sections/PhotoEditorVirtual/PhotoEditorToPrint";
import PhotoEditorToPrintDobleToBike from "../../../../Landing/sections/PhotoEditorVirtual/PhotoEditorToPrintDobleToBike";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "100%",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function EventRaceNumberToPrint() {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const eventParticipants = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );

  const createEventObject = createEventConfig.dorsalToPrint;

  const classes = useStyles();
  const [renderAll, setRenderAll] = useState(false);

  function handleChange(value) {
    console.log(value);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "inscripcionesConfiguracion/",
        value
      )
    );
  }
  function findValueByRoute(object, route, field) {
    const routeSplit = route.split("/");
    const fieldName = routeSplit[routeSplit.length - 1];
    let value = object;
    console.log(fieldName);
    console.log(value);
    for (var i = 0; i < routeSplit.length; i++) {
      value = value[routeSplit[i]] !== undefined ? value[routeSplit[i]] : "";
    }
    console.log(value);

    return value;
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="left">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h2"} component="h2">
              Dorsal PARA IMPRIMIR
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => setRenderAll(false)}>
              Parar renderizado
            </Button>
            <Button onClick={() => setRenderAll(true)}>Renderizado</Button>
            {registryConfiguration &&
              registryConfiguration.configuracion &&
              registryConfiguration.configuracion.dorsal &&
              renderAll && (
                <PhotoEditorToPrint
                  size="70%"
                  participant={{
                    raceNumber:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberTest || 1,
                    athleteName: "Dorsal de ",
                    athleteLastName: "Prueba",
                  }}
                  currentEvent={{
                    distance: 10,
                    raceNumberFontColor:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberFontColorToPrint || "#000000",
                    raceNumberFontColorBorder:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberFontColorBorderToPrint || "#000000",
                    backgroundColor:
                      registryConfiguration.configuracion.dorsal
                        .backgroundColor || "#000000",
                    raceNumberWhiteBack:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberWhiteBackToPrint || true,
                    raceNumberFontSize:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberFontSize || 340,
                    raceNumberVertical:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberVertical || 1,
                    raceNumberHorizontal:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberHorizontal || 1,
                    raceNumberColumns:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberColumns || 1,
                    raceNumberRows:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberRows || 1,
                    fontFamily:
                      registryConfiguration.configuracion.dorsal.fontFamily ||
                      "Forza",
                    fontType:
                      registryConfiguration.configuracion.dorsal.fontType ||
                      "normal",
                    initialNumber:
                      registryConfiguration.configuracion.dorsal
                        .initialNumber || 1,
                  }}
                  urlPhoto={
                    registryConfiguration.configuracion.dorsal
                      .raceNumberImageToPrint
                  }
                  type={"raceNumber"}
                />
              )}
          </Grid>
          <Grid item xs={12}>
            {registryConfiguration &&
              registryConfiguration.configuracion &&
              registryConfiguration.configuracion.dorsal &&
              !renderAll && (
                <PhotoEditorToPrint
                  size="70%"
                  participant={{
                    raceNumber:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberTest || 1,
                    athleteName: "Dorsal de ",
                    athleteLastName: "Prueba",
                  }}
                  currentEvent={{
                    distance: 10,
                    raceNumberFontColor:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberFontColorToPrint || "#000000",
                    raceNumberFontColorBorder:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberFontColorBorderToPrint || "#000000",
                    backgroundColor:
                      registryConfiguration.configuracion.dorsal
                        .backgroundColor || "#000000",
                    raceNumberWhiteBack:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberWhiteBackToPrint || true,
                    raceNumberFontSize:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberFontSize || 340,
                    raceNumberVertical:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberVertical || 1,
                    raceNumberHorizontal:
                      registryConfiguration.configuracion.dorsal
                        .raceNumberHorizontal || 1,
                    raceNumberColumns: 1,
                    raceNumberRows: 1,
                    fontFamily:
                      registryConfiguration.configuracion.dorsal.fontFamily ||
                      "Forza",
                    fontType:
                      registryConfiguration.configuracion.dorsal.fontType ||
                      "normal",
                    initialNumber:
                      registryConfiguration.configuracion.dorsal
                        .initialNumber || 1,
                  }}
                  urlPhoto={
                    registryConfiguration.configuracion.dorsal
                      .raceNumberImageToPrint
                  }
                  type={"raceNumber"}
                />
              )}
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            {registryConfiguration &&
              Object.values(createEventObject).map((field, index) => {
                const defaultValue = findValueByRoute(
                  registryConfiguration,
                  field.fieldName
                );
                return (
                  <EditableField
                    key={index}
                    fieldObject={{
                      ...field,
                      defaultValue: defaultValue,
                    }}
                    onSubmit={handleChange}
                  />
                );
              })}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
