import jsPDF from "jspdf";
import $ from "jquery";

export const DescargarCertificadoReg = (
  eventDetail,
  resultadoCorredor,
  formato,
  certificateConfig
) => {
  var format = formato == 1 ? "PDF" : "JPG";

  const Nombre = resultadoCorredor.nombre;
  const Num = resultadoCorredor.num;
  const Mod = resultadoCorredor.mod + " km";
  const Cat = resultadoCorredor.cat;
  const Gen = resultadoCorredor.gen;
  const TO = resultadoCorredor.to;
  const TC = resultadoCorredor.tc;
  const PG = resultadoCorredor.pG;
  const PR = resultadoCorredor.pR;
  const PC = resultadoCorredor.pCat;
  const AB = resultadoCorredor.ab;
  const Parciales = resultadoCorredor.parciales;

  var nombreEvento = eventDetail.nombreEvento;
  var logoEvento = eventDetail.logoEvento;

  // const certificateConfig = {
  //   certificateFontColor: "#000000",
  //   certificateWhiteBack: false,
  //   certificatePosition: 1,
  //   certificateImage:
  //     "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2FDASHPORT_white_horizontal.png?alt=media&token=af4ac181-a489-4716-94d6-9c0aca71ff73",
  // };

  if (certificateConfig) {
    generarCertificadoCustom(
      nombreEvento,
      certificateConfig.certificateImage,
      Nombre,
      Num,
      Mod,
      Cat,
      TO,
      PG,
      PR,
      PC,
      formato,
      certificateConfig
    );
  } else {
    generarCertificado(
      nombreEvento,
      logoEvento,
      Nombre,
      Num,
      Mod,
      Cat,
      TO,
      PG,
      PR,
      PC,
      formato
    );
  }
};

function generarCertificado(
  nombreEvento,
  logoEvento,
  nombreParticipante,
  numeroParticipante,
  modalidad,
  categoria,
  tiempoOficial,
  pG,
  pR,
  pCat,
  formato
) {
  // formato 1= pdf, 2= jpg;
  $("#myCanvas").remove();

  var canvasAppend = '<canvas style="display:none" id="myCanvas" ></canvas>';

  $("body").append(canvasAppend);

  var canvas = document.getElementById("myCanvas");
  var context = canvas.getContext("2d");
  const alto = 820;
  const ancho = alto * 1.2941;

  document.getElementById("myCanvas").width = ancho;
  document.getElementById("myCanvas").height = alto;

  // fondo blanco
  context.fillStyle = "#FFFFFF";
  context.fillRect(0, 0, ancho, alto);

  // header azul y logo
  context.fillStyle = "#000000";
  context.fillRect(0, 20, ancho, alto / 8);

  // descargo del servidor y calculo ancho y alto.

  //decomentar esta seccion para que se coloque el logo
  // fin header
  // inicio titulo
  var tituloCertificado = "Certificado de Participación";
  var fuente = 40;

  context.font = fuente + "px Arial";
  var i = context.measureText(tituloCertificado).width;
  var tituloCertificadoX = ancho / 2 - i / 2;
  var tituloCertificadoY = 180;
  context.fillStyle = "#212121";
  context.fillText(tituloCertificado, tituloCertificadoX, tituloCertificadoY);

  var fuente = 50;

  context.font = fuente + "px Arial";
  var i = context.measureText(nombreEvento).width;
  var nombreEventoX = ancho / 2 - i / 2;
  var nombreEventoY = 240;
  context.fillStyle = "black";
  context.fillText(nombreEvento, nombreEventoX + 1, nombreEventoY + 1);
  context.fillStyle = "#212121";
  context.fillText(nombreEvento, nombreEventoX, nombreEventoY);

  context.beginPath();
  context.lineWidth = "3";
  context.strokeStyle = "#B6B6B6"; // Green path
  context.moveTo(0, 260);
  context.lineTo(ancho, 260);
  context.stroke(); // Draw it
  // cierra titulo del evento con linea devisora
  // area de datos del atleta

  const nombreParticipanteTitle = "Nombre Participante:";
  const numeroParticipanteTitle = "Número:";
  const modalidadTitle = "Modalidad:";
  const categoriaTitle = "Categoría:";
  const tiempoOficialTitle = "Tiempo Oficial:";
  const pGTitle = "Posición General:";
  const pRTitle = "Posición Rama:";
  const pCatTitle = "Posición Categoría:";

  function dibujarTexto(titulo, texto, orden) {
    const centroDatos = 300;
    const altoDatos = 300;
    var fuente = 22;
    var entrelinea = 42;
    var espacioCentro = 10;

    context.font = "bold " + fuente + "px Arial";
    context.fillStyle = "#212121";
    var anchoTexto = context.measureText(titulo).width;
    var X = centroDatos - anchoTexto;
    var Y = altoDatos + orden * entrelinea;
    context.fillText(titulo, X, Y);
    context.font = fuente + "px Arial";
    context.fillText(texto, centroDatos + espacioCentro, Y);
  }

  dibujarTexto(nombreParticipanteTitle, nombreParticipante, 1);
  dibujarTexto(numeroParticipanteTitle, numeroParticipante, 2);
  dibujarTexto(modalidadTitle, modalidad, 3);
  dibujarTexto(categoriaTitle, categoria, 4);
  dibujarTexto(tiempoOficialTitle, tiempoOficial, 5);
  dibujarTexto(pGTitle, pG, 6);
  dibujarTexto(pRTitle, pR, 7);
  dibujarTexto(pCatTitle, pCat, 8);

  // fin logo evento

  // inicia pie de pagina

  context.beginPath();
  context.lineWidth = "3";
  context.strokeStyle = "#B6B6B6"; // Green path
  context.moveTo(0, 700);
  context.lineTo(ancho, 700);
  context.stroke(); // Draw it

  var pieDePagina = "www.dashport.run";
  var fuente = 40;

  context.font = fuente + "px Arial";
  var i = context.measureText(pieDePagina).width;
  var pieDePaginaX = ancho / 2 - i / 2;
  var pieDePaginaY = 750;
  context.fillStyle = "#B6B6B6";
  context.fillText(pieDePagina, pieDePaginaX, pieDePaginaY);

  // fin pie de pagina
  var url =
    "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2FDASHPORT_white_horizontal.png?alt=media&token=af4ac181-a489-4716-94d6-9c0aca71ff73";
  var img = new Image();
  img.src = url;
  img.crossOrigin = "Anonymous";
  img.onload = function() {
    var anchoLogo = img.width;
    var altoLogo = img.height;
    var anchoLogoPorcentaje = 0.2;
    // ajusto tamano en relacion al ancho especificado que ocupara en la foto
    var anchoLogoFinal = ancho * anchoLogoPorcentaje;
    var altoLogoFinal = (anchoLogoFinal * altoLogo) / anchoLogo;

    var posX = 50;
    var posY = 30;
    context.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      posX,
      posY,
      anchoLogoFinal,
      altoLogoFinal
    );

    return cargarLogoEvento();
  };

  // logo envento

  function cargarLogoEvento() {
    var img = new Image();
    img.crossOrigin = "anonymous";
    img.src = logoEvento;
    // descargo del servidor y calculo ancho y alto.

    //decomentar esta seccion para que se coloque el logo
    img.onload = function() {
      var anchoLogo = img.width;
      var altoLogo = img.height;
      var anchoLogoPorcentaje = 0.25;
      // ajusto tamano en relacion al ancho especificado que ocupara en la foto
      var anchoLogoFinal = ancho * anchoLogoPorcentaje;
      var altoLogoFinal = (anchoLogoFinal * altoLogo) / anchoLogo;
      var posX = 600;
      var posY = 400;
      context.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        posX,
        posY,
        anchoLogoFinal,
        altoLogoFinal
      );
      return descargarArchivo(formato);
    };
  }

  function descargarArchivo(formato) {
    console.log("descarga");
    if (formato == 1) {
      // only jpeg is supported by jsPDF

      try {
        var imgData = canvas.toDataURL("image/jpeg");
        var pdf = new jsPDF("l", "pt", "letter");

        pdf.addImage(imgData, "JPEG", 0, 0);
        var download = document.getElementById("download");

        pdf.save(
          nombreEvento + ", certificado - " + nombreParticipante + ".pdf"
        );
      } catch (err) {
        //download();
        console.log(err);
      }
    } else {
      // var dato = canvas.toDataURL("image/jpeg");
      // dato = dato.replace("image/jpeg", "image/octet-stream");
      // document.location.href = dato;

      var link = document.createElement("a");
      link.download =
        nombreEvento + ", certificado - " + nombreParticipante + ".jpeg";
      link.href = document.getElementById("myCanvas").toDataURL("image/jpeg");
      link.click();
    }
  }
}

function generarCertificadoCustom(
  nombreEvento,
  logoEvento,
  nombreParticipante,
  numeroParticipante,
  modalidad,
  categoria,
  tiempoOficial,
  pG,
  pR,
  pCat,
  formato,
  certificateConfig
) {
  const color = certificateConfig.certificateFontColor || "#000000";

  // formato 1= pdf, 2= jpg;
  $("#myCanvas").remove();

  var canvasAppend = '<canvas style="display:none" id="myCanvas" ></canvas>';

  $("body").append(canvasAppend);

  var canvas = document.getElementById("myCanvas");
  var context = canvas.getContext("2d");
  const alto = 820;
  const ancho = alto * (10 / 8);

  document.getElementById("myCanvas").width = ancho;
  document.getElementById("myCanvas").height = alto;

  // fondo blanco
  context.fillStyle = "#FFFFFF";
  context.fillRect(0, 0, ancho, alto);

  const nombreParticipanteTitle = "Felicidades";
  const numeroParticipanteTitle = "Número:";
  const modalidadTitle = "Modalidad:";
  const categoriaTitle = "Categoría:";
  const tiempoOficialTitle = "Tiempo Oficial:";
  const pGTitle = "Posición General:";
  const pRTitle = "Posición Rama:";
  const pCatTitle = "Posición Categoría:";

  cargarLogoEvento(logoEvento)
    .then(() => {
      if (!certificateConfig.certificateWhiteBack) {
        context.fillStyle = "#ffcdd2";
        context.globalAlpha = 0.5;
        context.fillRect(ancho * 0.1 + 2, 200 + 2, ancho * 0.8, 400);
        context.fillStyle = "#FFFFFF";
        context.fillRect(ancho * 0.1, 200, ancho * 0.8, 400);
      }
      context.globalAlpha = 1;
      var url =
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2Ffondogris_Dashport.jpg?alt=media&token=75003445-0707-4394-a1f6-6a0737a1a72f";
      return false; //insertarImagen(url, ancho * 0.1,200, 0.5, 0);
    })
    .then(() => {
      const lugarLogo = certificateConfig.certificatePosition || 1;
      const lugarVerticalLogo =
        certificateConfig.certificatePositionVertical || 0.7;
      var url =
        certificateConfig.logoDashport ||
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2FDASHPORT_color.png?alt=media&token=896a4014-309c-47fc-97f3-341800f7ff81";
      return insertarImagen(
        url,
        (ancho * lugarLogo) / 2 + 200,
        (alto * lugarVerticalLogo) / 2 + 200,
        0.15,
        0
      );
    })
    .then(() => {
      const lugar = certificateConfig.certificatePosition || 1;
      const lugarVertical =
        certificateConfig.certificatePositionVertical || 0.7;
      dibujarTextoCentradoNew(
        nombreParticipanteTitle,
        "",
        1,
        20,
        "Forza",
        color,
        lugar,
        lugarVertical
      );
      dibujarTextoCentradoNew(
        nombreParticipante,
        "",
        2,
        40,
        "Forza",
        color,
        lugar,
        lugarVertical
      );
      dibujarTexto(
        numeroParticipanteTitle,
        numeroParticipante,
        4,
        23,
        "Arial",
        color,
        lugar,
        lugarVertical
      );
      dibujarTexto(
        modalidadTitle,
        modalidad,
        5,
        23,
        "Arial",
        color,
        lugar,
        lugarVertical
      );
      dibujarTexto(
        categoriaTitle,
        categoria,
        6,
        23,
        "Arial",
        color,
        lugar,
        lugarVertical
      );
      dibujarTexto(
        tiempoOficialTitle,
        tiempoOficial,
        7,
        23,
        "Arial",
        color,
        lugar,
        lugarVertical
      );
      dibujarTexto(pGTitle, pG, 8, 23, "Arial", color, lugar, lugarVertical);
      dibujarTexto(pRTitle, pR, 9, 23, "Arial", color, lugar, lugarVertical);
      dibujarTexto(
        pCatTitle,
        pCat,
        10,
        23,
        "Arial",
        color,
        lugar,
        lugarVertical
      );

      return descargarArchivo(formato);
    });

  function dibujarTextoCentradoNew(
    titulo,
    texto,
    orden,
    fuenteTamano,
    fuente,
    color,
    lugar,
    lugarVertical
  ) {
    const centroDatos = (ancho / 2) * lugar;
    const altoDatos = (alto / 2) * lugarVertical || 270;
    var entrelinea = 36;

    context.font = "bold " + fuenteTamano + "px " + fuente;
    context.fillStyle = color;
    var anchoTexto = context.measureText(titulo).width;
    var X = centroDatos - anchoTexto / 2;
    var Y = altoDatos + orden * entrelinea;
    context.fillText(titulo, X, Y);
  }

  function dibujarTexto(
    titulo,
    texto,
    orden,
    fuenteTamano,
    fuente,
    color,
    lugar,
    lugarVertical
  ) {
    const centroDatos = (ancho / 2) * lugar;
    const altoDatos = (alto / 2) * lugarVertical || 270;
    var entrelinea = 36;
    var espacioCentro = 10;

    context.font = "bold " + fuenteTamano + "px " + fuente;
    context.fillStyle = color;
    var anchoTexto = context.measureText(titulo).width;
    var X = centroDatos - anchoTexto;
    var Y = altoDatos + orden * entrelinea;
    context.fillText(titulo, X, Y);
    context.font = fuenteTamano + "px " + fuente;
    context.fillText(texto, centroDatos + espacioCentro, Y);
  }
  function dibujarTextoCentrado(
    texto,
    fuenteTamano,
    textoY,
    fuente,
    color,
    lugar,
    lugarVertical
  ) {
    context.font = "bold " + fuenteTamano + "px " + fuente;
    var i = context.measureText(texto).width;
    var textoX = (ancho / 2) * lugar - i / 2;
    var textoY1 = textoY;
    context.fillStyle = "black";
    context.fillText(texto, textoX + 1, (textoY / alto) * lugarVertical + 1);
    context.fillStyle = color;
    context.fillText(texto, textoX, (textoY / alto) * lugarVertical);
  }

  function insertarImagen(
    url,
    posX,
    posY,
    anchoLogoPorcentaje,
    altoLogoPorcentaje
  ) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.src = url;
      img.crossOrigin = "Anonymous";
      img.onload = function() {
        var anchoLogo = img.width;
        var altoLogo = img.height;
        // ajusto tamano en relacion al ancho especificado que ocupara en la foto
        var anchoLogoFinal = ancho * anchoLogoPorcentaje;
        var altoLogoFinal = (anchoLogoFinal * altoLogo) / anchoLogo;
        if (altoLogoPorcentaje > 0) {
          altoLogoFinal = alto * altoLogoPorcentaje;
        }

        context.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          posX,
          posY,
          anchoLogoFinal,
          altoLogoFinal
        );
        resolve(true);
      };
    });
  }
  function cargarLogoEvento(logoEvento) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.crossOrigin = "anonymous";
      img.src = logoEvento;
      // descargo del servidor y calculo ancho y alto.

      //decomentar esta seccion para que se coloque el logo
      return (img.onload = function() {
        // ajusto tamano en relacion al ancho especificado que ocupara en la foto
        var anchoLogoFinal = ancho;
        var altoLogoFinal = alto;
        var posX = 0;
        var posY = 0;
        context.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          posX,
          posY,
          anchoLogoFinal,
          altoLogoFinal
        );
        resolve(true);
      });
    });
  }

  function descargarArchivo(formato) {
    console.log("descarga");
    if (formato == 1) {
      // only jpeg is supported by jsPDF

      try {
        var imgData = canvas.toDataURL("image/jpeg");
        var pdf = new jsPDF("l", "pt", "letter");

        pdf.addImage(imgData, "JPEG", 0, 0);
        var download = document.getElementById("download");

        pdf.save(
          nombreEvento + ", certificado - " + nombreParticipante + ".pdf"
        );
      } catch (err) {
        //download();
        console.log(err);
      }
    } else {
      var link = document.createElement("a");
      link.download =
        nombreEvento + ", certificado - " + nombreParticipante + ".jpeg";
      link.href = document.getElementById("myCanvas").toDataURL("image/jpeg");
      link.click();
    }
  }
}
