export const INIT_EVENTS = "INIT_EVENTS";
export const EVENT_DETAIL = "EVENT_DETAIL";
export const EVENT_CONFIGURATION = "EVENT_CONFIGURATION";
export const EVENT_RESULTS = "EVENT_RESULTS";
export const EVENT_RESULTS_REGISTRY = "EVENT_RESULTS_REGISTRY";
export const EVENT_RESULTS_REGISTRY_OBJECT = "EVENT_RESULTS_REGISTRY_OBJECT";
export const ATHLETE_JUEZ_REGISTRY = "ATHLETE_JUEZ_REGISTRY";
export const EVENT_PHOTOS_CONFIGURATION = "EVENT_PHOTOS_CONFIGURATION";
export const EVENT_POINTS_CONTROLS = "EVENT_POINTS_CONTROLS";
export const EVENT_USERS = "EVENT_USERS";
export const ATLETE_PHOTOS = "ATLETE_PHOTOS";
export const ATLETE_ACCOUNT = "ATLETE_ACCOUNT";
export const REQUESTTOKENGARMIN = "REQUESTTOKENGARMIN";
export const ACTIVITIESGARMIN = "ACTIVITIESGARMIN";
export const ACTIVITIESGARMINDETAIL = "ACTIVITIESGARMINDETAIL";
export const ACTIVITIESGARMINLOADING = "ACTIVITIESGARMINLOADING";

export const POSTWORKOUTGARMIN = "POSTWORKOUTGARMIN";

export const CUSTOM_STRINGS = "CUSTOM_STRINGS";

export const EVENT_CONVOCATORY = "EVENT_CONVOCATORY";
export const EVENT_WEB = "EVENT_WEB";
export const EVENT_SUBEVENTS = "EVENT_SUBEVENTS";
export const EVENT_SUBEVENTS_REGISTRY = "EVENT_SUBEVENTS_REGISTRY";
export const EVENT_PARTICIPANTS = "EVENT_PARTICIPANTS";
export const EVENT_PARTICIPANTS_CHANGED = "EVENT_PARTICIPANTS_CHANGED";
export const EVENT_PARTICIPANTS_REMOVED = "EVENT_PARTICIPANTS_REMOVED";
export const EVENT_PASS = "EVENT_PASS";
export const REGISTRY_CONFIGURATION = "REGISTRY_CONFIGURATION";
export const REGISTRY_AUTORIZED_USERS = "REGISTRY_AUTORIZED_USERS";
export const REGISTRY_PARTICIPANT_DATA = "REGISTRY_PARTICIPANT_DATA";
export const RESET_REGISTRY_PARTICIPANT_DATA =
  "RESET_REGISTRY_PARTICIPANT_DATA";
export const CODES_LIST = "CODES_LIST";
export const CODES_LIST_CHANGED = "CODES_LIST_CHANGED";
export const CODES_LIST_REMOVED = "CODES_LIST_REMOVED";
export const CODES_GROUPS = "CODES_GROUPS";
export const EVENT_DATA_TO_COPY = "EVENT_DATA_TO_COPY";
export const EVENT_DEPOSITS_LIST = "EVENT_DEPOSITS_LIST";
export const EVENT_DEPOSITS_REQUESTED = "EVENT_DEPOSITS_REQUESTED";
export const EVENT_DEPOSITS_USERS = "EVENT_DEPOSITS_USERS";
export const EVENTS_ACCOUNT = "EVENTS_ACCOUNT";

export const CREATE_VIRTUAL_EVENT = "CREATE_VIRTUAL_EVENT";
export const REGISTRY_VIRTUAL_EVENT = "REGISTRY_VIRTUAL_EVENT";
export const USER_MY_VIRTUAL_EVENTS = "USER_MY_VIRTUAL_EVENTS";
export const USER_MY_ORGANIZED_VIRTUAL_EVENTS =
  "USER_MY_ORGANIZED_VIRTUAL_EVENTS";
export const CURRENT_EVENT = "CURRENT_EVENT";
export const RESET_CURRENT_EVENT = "RESET_CURRENT_EVENT";
export const CURRENT_EVENT_AUTHORIZED = "CURRENT_EVENT_AUTHORIZED";
export const CURRENT_EVENT_PARTICIPANTS = "CURRENT_EVENT_PARTICIPANTS";
export const CURRENT_EVENT_PARTICIPANTS_REQUEST =
  "CURRENT_EVENT_PARTICIPANTS_REQUEST";

export const TRAKING_ACTIVITY_APP = "TRAKING_ACTIVITY_APP";

export const UPLOADING_PHOTOS = "UPLOADING_PHOTOS";

// organizer
export const ORGANIZER_CONFIG = "ORGANIZER_CONFIG";
export const ORGANIZER_WEB = "ORGANIZER_WEB";
export const EVENT_WEB_PUBLIC = "EVENT_WEB_PUBLIC";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const ORGANIZER_EVENTS = "ORGANIZER_EVENTS";
export const BUSINESS_PARTICIPANTS = "BUSINESS_PARTICIPANTS";

// business
export const BUSINESS_GENERAL_DATA = "BUSINESS_GENERAL_DATA";

// form
export const REGISTRY_FORM_DATA = "REGISTRY_FORM_DATA";
export const FORM_DATA = "FORM_DATA";

// tournaments
export const TOURNAMENTS_LIST = "TOURNAMENTS_LIST";
export const TOURNAMENT_DATA = "TOURNAMENT_DATA";
export const TOURNAMENT_RESULTS = "TOURNAMENT_RESULTS";
export const TOURNAMENT_CREATED = "TOURNAMENT_CREATED";
export const PARTICIPANTS_LIST = "PARTICIPANTS_LIST";

export const CUSTOM_VIEWS = "CUSTOM_VIEWS";

export const TRACKING_LINKS = "TRACKING_LINKS";

export const LOCAL_REGISTRIES = "LOCAL_REGISTRIES";

// checkChip
export const EVENT_CHECKCHIP = "EVENT_CHECKCHIP";

export const READERS_LIST = "READERS_LIST";
