import React from "react";
import EXIF from "exif-js";

//------------------------------------------------------------------------------------
export const recorrerObjetoJsonLoop = (objeto) => {
  var tab = 0;

  var multiploEspacio = 50;

  var Campos = [];
  recorrerObjetoJson(objeto, tab);
  function recorrerObjetoJson(objeto, tab) {
    var TabPadre = tab;
    for (var i in objeto) {
      if (objeto.hasOwnProperty(i)) {
        var val = objeto[i];
        if (typeof val == "object") {
          Campos.push(
            <div
              style={{ paddingLeft: tab * multiploEspacio, textAlign: "left" }}
            >
              {i}
            </div>
          );
          tab++;
          recorrerObjetoJson(val, tab);
          tab--;
        } else {
          Campos.push(
            <div
              style={{ paddingLeft: tab * multiploEspacio, textAlign: "left" }}
            >
              {" "}
              {i}:{val}
            </div>
          );
        }
      }
    }
    return Campos;
  }
  return Campos;
};

//------------------------------------------------------------------------------------
export const fechaMesDia = (fecha) => {
  var hoy;
  if (fecha == 0) {
    hoy = new Date();
  } else {
    hoy = new Date(fecha);
  }
  var dd = hoy.getDate();
  var mm = hoy.getMonth() + 1; //hoy es 0!
  var yyyy = hoy.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  hoy = dd + "/" + mm;
  return hoy;
};

//------------------------------------------------------------------------------------
export const calcularEdad = (fechaNacimiento, fechaEvento, edadActual) => {
  if (fechaNacimiento == undefined) {
    return 0;
  }

  // Si la fecha es correcta, calculamos la edad
  var values = fechaNacimiento.split("-");

  if (values[2] == undefined) {
    values = fechaNacimiento.split("/");
  }
  var dia = values[2];
  var mes = values[1];
  var ano = values[0];

  var fecha_hoy = fechaEvento.split("-");

  if (edadActual) {
    // si edad actual el true se toma la fecha del evento.
    var ahora_dia = fecha_hoy[2];
    var ahora_mes = fecha_hoy[1];
    var ahora_ano = fecha_hoy[0];
  } else {
    // tomamos el 31 de diciembre del a;o del evento
    var ahora_dia = 31;
    var ahora_mes = 12;
    var ahora_ano = fecha_hoy[0];
  }

  // realizamos el calculo
  var edad = ahora_ano - ano;
  if (ahora_mes < mes) {
    edad--;
  }
  if (mes == ahora_mes && ahora_dia < dia) {
    edad--;
  }
  if (edad > 1900) {
    edad -= 1900;
  }

  return edad;
};
//------------------------------------------------------------------------------------
export const getCleanedString = (cadena) => {
  cadena.toUpperCase();
  // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
  cadena = cadena.replace(/Á/gi, "A");
  cadena = cadena.replace(/É/gi, "E");
  cadena = cadena.replace(/Í/gi, "I");
  cadena = cadena.replace(/Ó/gi, "O");
  cadena = cadena.replace(/Ú/gi, "U");
  cadena = cadena.replace(/Ñ/gi, "N");

  return cadena;
};

//------------------------------------------------------------------------------------
export const formatEventDate = (date) => {
  var split = date.split("-");
  return split[2] + "-" + split[1] + "-" + split[0];
};

//------------------------------------------------------------------------------------
export const fechaSeparada = (fecha) => {
  if (fecha == undefined) {
    return 0;
  }

  // Si la fecha es correcta, calculamos la edad
  var values = fecha.split("-");

  if (values[2] == undefined) {
    values = fecha.split("/");
  }
  var dia = values[2];
  var mes = values[1];
  var ano = values[0];

  return { dia: dia, mes: mes, ano, ano };
};
//------------------------------------------------------------------------------------
export const modalidadCateogoriaNameByCode = (
  ModalidadesEventoSnap,
  codigoModalidad,
  codigoCategoria,
  Genero
) => {
  /*
    var modalidad = ModalidadesEventoSnap.child("modalidad_"+codigoModalidad+"/descripcionModalidad/").val();
    var categoria = ModalidadesEventoSnap.child("modalidad_"+codigoModalidad+"/ramaCat/rama_"+
      Genero+"/categorias/categoria_"+codigoCategoria+"/nombreCategoria/").val();
*/
  var modalidad = ModalidadesEventoSnap["modalidad_" + codigoModalidad];
  var modalidadDesc =
    modalidad != undefined ? modalidad["descripcionModalidad"] : undefined;
  var Rama =
    modalidad != undefined ? modalidad.ramaCat["rama_" + Genero] : undefined;
  var categoria =
    Rama != undefined
      ? Rama.categorias["categoria_" + codigoCategoria]
      : undefined;
  var categoriaNombre =
    categoria != undefined ? categoria.nombreCategoria : undefined;

  var modalidadCategoria = {
    modalidad: modalidadDesc,
    categoria: categoriaNombre,
  };
  return modalidadCategoria;
};
//------------------------------------------------------------------------------------
export const completeNull = (value) => {
  if (value == undefined) {
    return "pendiente";
  } else if (value === null) {
    return "pendiente";
  } else if (typeof value === "object") {
    return "pendiente";
  } else if (value === true) {
    return "si";
  } else if (value === false) {
    return "no";
  } else if (typeof value === "string") {
    return value.replace(/,|;/gi, "");
  } else {
    return value;
  }
};

//------------------------------------------------------------------------------------
export const fechahora = () => {
  var hoy = new Date();

  var mes = hoy.getMonth() + 1;

  var dd = hoy.getDate() < 10 ? "0" + hoy.getDate() : hoy.getDate();
  var mm = mes < 10 ? "0" + mes : mes;
  var yyyy = hoy.getFullYear();

  var h = hoy.getHours() < 10 ? "0" + hoy.getHours() : hoy.getHours();
  var m = hoy.getMinutes() < 10 ? "0" + hoy.getMinutes() : hoy.getMinutes();
  var s = hoy.getSeconds() < 10 ? "0" + hoy.getSeconds() : hoy.getSeconds();

  hoy = yyyy + "/" + mm + "/" + dd + " " + h + ":" + m + ":" + s;
  return hoy;
};
//------------------------------------------------------------------------------------
export const fechahoraGuionAndMiliseconds = () => {
  var hoy;
  hoy = new Date();

  var mes = hoy.getMonth() + 1;

  var dd = hoy.getDate() < 10 ? "0" + hoy.getDate() : hoy.getDate();
  var mm = mes < 10 ? "0" + mes : mes;
  var yyyy = hoy.getFullYear();

  var h = hoy.getHours() < 10 ? "0" + hoy.getHours() : hoy.getHours();
  var m = hoy.getMinutes() < 10 ? "0" + hoy.getMinutes() : hoy.getMinutes();
  var s = hoy.getSeconds() < 10 ? "0" + hoy.getSeconds() : hoy.getSeconds();
  var ms =
    hoy.getMilliseconds() >= 100
      ? hoy.getMilliseconds()
      : hoy.getMilliseconds() >= 10
      ? "0" + hoy.getMilliseconds()
      : "00" + hoy.getMilliseconds();

  const hoyNew =
    yyyy + "-" + mm + "-" + dd + " " + h + ":" + m + ":" + s + "." + ms;
  return { text: hoyNew, timestamp: hoy.getTime() };
};
//------------------------------------------------------------------------------------
export const segundosATiempo = (Segundos) => {
  var hours = Math.floor(Segundos / 3600);
  var minutes = Math.floor((Segundos % 3600) / 60);
  var seconds = Math.floor(Segundos % 60);

  //Anteponiendo un 0 a los horas si son menos de 10
  hours = hours < 10 ? "0" + hours : hours;

  //Anteponiendo un 0 a los minutos si son menos de 10
  minutes = minutes < 10 ? "0" + minutes : minutes;

  //Anteponiendo un 0 a los segundos si son menos de 10
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds; // 2:41:30
};
//------------------------------------------------------------------------------------
export const tiempoASegundos = (Tiempo) => {
  var divTime = Tiempo.split(":");
  var H = divTime[0] / 1;
  var M = divTime[1] / 1;
  var S = divTime[2] / 1;

  return H * 60 * 60 + M * 60 + S;
};
//------------------------------------------------------------------------------------
export const RestarTiempos = (Tiempo1, Tiempo2) => {
  // restar tiempos, el tiempo2 es mayor que el tiempo 1
  console.log("Tiempo1", Tiempo1);
  console.log("Tiempo2", Tiempo2);

  var divTime = Tiempo1.split(":");
  var H1 = divTime[0] / 1;
  var M1 = divTime[1] / 1;
  var S1 = divTime[2] / 1;
  var Segundos1 = H1 * 60 * 60 + M1 * 60 + S1;

  var divTime2 = Tiempo2.split(":");
  var H2 = divTime2[0] / 1;
  var M2 = divTime2[1] / 1;
  var S2 = divTime2[2] / 1;
  var Segundos2 = H2 * 60 * 60 + M2 * 60 + S2;

  var SegundosFinal = Segundos2 - Segundos1;

  var hours = Math.floor(SegundosFinal / 3600);
  var minutes = Math.floor((SegundosFinal % 3600) / 60);
  var seconds = Math.floor(SegundosFinal % 60);

  //Anteponiendo un 0 a los minutos si son menos de 10
  minutes = minutes < 10 ? "0" + minutes : minutes;

  //Anteponiendo un 0 a los segundos si son menos de 10
  seconds = seconds < 10 ? "0" + seconds : seconds;

  var result = hours + ":" + minutes + ":" + seconds; // 2:41:30
  console.log("Tiempo3", result);

  return result;
};

//------------------------------------------------------------------------------------

export const operarTiempos = (tiempo1, tiempo2, operacion) => {
  const [h1, m1, s1] = tiempo1.split(":").map(Number);
  const [h2, m2, s2] = tiempo2.split(":").map(Number);
  console.log("TiempoNew1", tiempo1);
  console.log("TiempoNew2", tiempo2);

  let totalSegundos1 = h1 * 3600 + m1 * 60 + s1;
  let totalSegundos2 = h2 * 3600 + m2 * 60 + s2;

  let resultadoSegundos =
    operacion === "sumar"
      ? totalSegundos1 + totalSegundos2
      : totalSegundos1 - totalSegundos2;

  // Asegurar que no haya valores negativos
  resultadoSegundos = Math.max(0, resultadoSegundos);

  const horas = Math.floor(resultadoSegundos / 3600);
  const minutos = Math.floor((resultadoSegundos % 3600) / 60);
  const segundos = resultadoSegundos % 60;

  const tiempo = [horas, minutos, segundos]
    .map((unit) => unit.toString().padStart(2, "0"))
    .join(":");

  console.log("TiempoNew3", tiempo2);

  return tiempo;
};
//------------------------------------------------------------------------------------
export const TiempoAPasoMinxK = (Tiempo, Distancia) => {
  var divTime = Tiempo.split(":");
  var H = divTime[0] / 1;
  var M = divTime[1] / 1;
  var S = divTime[2] / 1;

  var Segundos = (H * 60 * 60 + M * 60 + S) / Distancia;

  var minutes = Math.floor(Segundos / 60);
  var seconds = Math.floor(Segundos % 60);

  //Anteponiendo un 0 a los minutos si son menos de 10
  minutes = minutes < 10 ? "0" + minutes : minutes;

  //Anteponiendo un 0 a los segundos si son menos de 10
  seconds = seconds < 10 ? "0" + seconds : seconds;

  var result = minutes + ":" + seconds; // 161:30

  return result;
};
//------------------------------------------------------------------------------------
export const TiempoAPasoBy = (Tiempo, Distancia, type) => {
  let divTime = Tiempo.split(":");
  let H = divTime[0] / 1;
  let M = divTime[1] / 1;
  let S = divTime[2] / 1;

  if (type === "min/100mts") {
    let Segundos = (H * 60 * 60 + M * 60 + S) / (Distancia * 10);

    let minutes = Math.floor(Segundos / 60);
    let seconds = Math.floor(Segundos % 60);

    //Anteponiendo un 0 a los minutos si son menos de 10
    minutes = minutes < 10 ? "0" + minutes : minutes;

    //Anteponiendo un 0 a los segundos si son menos de 10
    seconds = seconds < 10 ? "0" + seconds : seconds;
    return minutes + ":" + seconds + " min/100mts"; // 161:30
  } else if (type === "km/h") {
    let kmporHora = Distancia / ((H * 60 * 60 + M * 60 + S) / 3600);

    return Math.floor(kmporHora * 100) / 100 + " km/h"; // 161:30
  }

  let Segundos = (H * 60 * 60 + M * 60 + S) / Distancia;

  let minutes = Math.floor(Segundos / 60);
  let seconds = Math.floor(Segundos % 60);

  //Anteponiendo un 0 a los minutos si son menos de 10
  minutes = minutes < 10 ? "0" + minutes : minutes;

  //Anteponiendo un 0 a los segundos si son menos de 10
  seconds = seconds < 10 ? "0" + seconds : seconds;

  let result = minutes + ":" + seconds + " min/k"; // 161:30

  return result;
};

export const timestampToDate = (timestamp) => {
  console.log(timestamp);
  var fecha = new Date(timestamp);
  var seg =
    fecha.getSeconds() < 10 ? "0" + fecha.getSeconds() : fecha.getSeconds();
  var min =
    fecha.getMinutes() < 10 ? "0" + fecha.getMinutes() : fecha.getMinutes();
  var horas = fecha.getHours() < 10 ? "0" + fecha.getHours() : fecha.getHours();
  var dia = fecha.getDate() < 10 ? "0" + fecha.getDate() : fecha.getDate();
  console.log(dia);
  var mes =
    fecha.getMonth() + 1 < 10
      ? "0" + (fecha.getMonth() + 1)
      : fecha.getMonth() + 1;
  var year = fecha.getFullYear();
  var fechaFinal =
    year + "/" + mes + "/" + dia + " " + horas + ":" + min + ":" + seg;

  return fechaFinal;
};
// ##########################################################################################
export const sumarSegAFecha = (fechaParam, segundosParam) => {
  var fechaTomada = new Date(fechaParam);
  var fecha = new Date(
    fechaTomada.setTime(fechaTomada.getTime() + segundosParam * 1000)
  );
  console.log("segundos " + fechaTomada.getTime());

  var seg =
    fecha.getSeconds() < 10 ? "0" + fecha.getSeconds() : fecha.getSeconds();
  var min =
    fecha.getMinutes() < 10 ? "0" + fecha.getMinutes() : fecha.getMinutes();
  var horas = fecha.getHours() < 10 ? "0" + fecha.getHours() : fecha.getHours();
  var dia = fecha.getDate() < 10 ? "0" + fecha.getDate() : fecha.getDate();
  console.log(dia);
  var mes =
    fecha.getMonth() + 1 < 10
      ? "0" + (fecha.getMonth() + 1)
      : fecha.getMonth() + 1;
  var year = fecha.getFullYear();
  var fechaFinal =
    year + "-" + mes + "-" + dia + " " + horas + ":" + min + ":" + seg;

  return fechaFinal;
};
// ##########################################################################################
export const fecha = (fecha) => {
  var hoy;
  if (fecha === 0) {
    hoy = new Date(); //hora
  } else {
    hoy = new Date(fecha);
  }
  var dd = hoy.getDate();
  var mm = hoy.getMonth() + 1; //hoy es 0!
  var yyyy = hoy.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return yyyy + "-" + mm + "-" + dd;
};
// ##########################################################################################
export const fechaDiagonal = (fecha) => {
  var hoy;
  if (fecha === 0) {
    hoy = new Date(); //hora
  } else {
    hoy = new Date(fecha);
  }
  var dd = hoy.getDate();
  var mm = hoy.getMonth() + 1; //hoy es 0!
  var yyyy = hoy.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return yyyy + "/" + mm + "/" + dd;
};
// ##########################################################################################
export const formatfechaDDMMYYYFromYYYYMMDD = (fecha) => {
  var fecha = fecha.split("-");
  var day = fecha[2] < 10 ? `0${fecha[2]}` : fecha[2];
  var month = fecha[2] < 10 ? `0${fecha[2]}` : fecha[1];
  return fecha[2] + "-" + fecha[1] + "-" + fecha[0];
};
// ##########################################################################################

export const restarFechas = (fechaInicio, fechaFin) => {
  if (
    fechaFin.localeCompare("") === 0 ||
    fechaFin.localeCompare("0000-00-00 00:00:00") === 0 ||
    fechaInicio.localeCompare("") === 0 ||
    fechaInicio.localeCompare("0000-00-00 00:00:00") === 0
  ) {
    return "00:00:00";
  } else {
    var Inicio = new Date(fechaInicio);
    var Fin = new Date(fechaFin);

    var InicioSeg = Inicio.getTime() / 1000;
    var FinSeg = Fin.getTime() / 1000;

    SegundosFinal = FinSeg - InicioSeg;

    var hours = Math.floor(SegundosFinal / 3600);
    var minutes = Math.floor((SegundosFinal % 3600) / 60);
    var seconds = Math.floor(SegundosFinal % 60);

    //Anteponiendo un 0 a lass horas si son menos de 10
    hours = hours < 10 ? "0" + hours : hours;

    //Anteponiendo un 0 a los minutos si son menos de 10
    minutes = minutes < 10 ? "0" + minutes : minutes;

    //Anteponiendo un 0 a los segundos si son menos de 10
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds; // 2:41:30
  }
};

export const sortJSON = (data, key, way) => {
  return data.sort(function(a, b) {
    var x = a[key];
    var y = b[key];
    if (way === "123") {
      return x < y ? -1 : x > y ? 1 : 0;
    }
    if (way === "321") {
      return x > y ? -1 : x < y ? 1 : 0;
    }
  });
};

// funcion de ordenamiento
export const helperMultisort = (arr, columns, order_by) => {
  if (typeof columns === "undefined") {
    columns = [];
    for (var x = 0; x < arr[0].length; x++) {
      columns.push(x);
    }
  }

  if (typeof order_by === "undefined") {
    order_by = [];
    for (x = 0; x < arr[0].length; x++) {
      order_by.push("ASC");
    }
  }

  function multisort_recursive(a, b, columns, order_by, index) {
    var direction = order_by[index] === "DESC" ? 1 : 0;

    var is_numeric = !isNaN(+a[columns[index]] - +b[columns[index]]);

    var x = is_numeric ? +a[columns[index]] : a[columns[index]].toLowerCase();
    var y = is_numeric ? +b[columns[index]] : b[columns[index]].toLowerCase();

    if (x < y) {
      return direction === 0 ? -1 : 1;
    }

    if (x === y) {
      return columns.length - 1 > index
        ? multisort_recursive(a, b, columns, order_by, index + 1)
        : 0;
    }

    return direction === 0 ? 1 : -1;
  }

  return arr.sort(function(a, b) {
    return multisort_recursive(a, b, columns, order_by, 0);
  });
};
// ##########################################################################################
// ################################## UTILS PARA FOTOS ##########################################
// ##########################################################################################
// ##########################################################################################
export function getPhotoFileToUpload(file, size, photoType, currentEvent) {
  if (!file) {
    return false;
  }
  return new Promise((resolve, reject) => {
    console.log(file.name);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function(evt) {
      var fechaTomada = "";
      var orientacion = "";
      EXIF.getData(file, function() {
        fechaTomada = EXIF.getTag(this, "DateTime");
        orientacion = 1; //EXIF.getTag(this, "Orientation");
        console.log(orientacion);

        var img = new Image();
        img.src = reader.result;
        img.onload = function() {
          resolve(
            resizeImageToUploadAndRotate(
              img,
              size,
              orientacion,
              photoType,
              currentEvent
            )
          );
        };
      });
    };
    reader.onerror = function(evt) {
      reject(false);
    };
  });
}

// ##########################################################################
export function makeid(largo) {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < largo; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}
// ##########################################################################

export function makeidCodes(largo) {
  var text = "";
  var possible = "ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz123456789";

  for (var i = 0; i < largo; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}
// ##########################################################################
function resizeImageToUploadAndRotate(
  img,
  maxsize,
  srcOrientation,
  photoType,
  currentEvent
) {
  var width = img.width,
    height = img.height,
    canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d");

  var MAX_WIDTH = maxsize;
  var MAX_HEIGHT = maxsize;

  // set proper canvas dimensions before transform & export
  if ([5, 6, 7, 8].indexOf(srcOrientation) > -1) {
    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = height;
    canvas.height = width;
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }
    canvas.width = width;
    canvas.height = height;
  }
  // set proper canvas dimensions before transform & export

  // transform context before drawing image
  switch (srcOrientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, width, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, width, height);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, height);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      ctx.transform(0, 1, -1, 0, height, 0);
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, height, width);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, width);
      break;
    default:
      ctx.transform(1, 0, 0, 1, 0, 0);
  }

  ctx.drawImage(img, 0, 0, width, height);

  //var dato = canvas.toDataURL("image/jpeg");
  //dato = dato.replace("image/jpeg", "image/octet-stream");
  //document.location.href = dato;

  //   var logos = [ {
  //     "anchoLogo" : 0.3,
  //     "codLogo" : 2,
  //     "descripcion" : "Logo Dashport",
  //     "margen" : 30,
  //     "posicion" : 4,
  //     "rutaLogo" : "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2FDASHPORT_white_horizontal.png?alt=media&token=af4ac181-a489-4716-94d6-9c0aca71ff73"
  //   } ]
  //   insertaLogos (logos);

  var blob = dataURItoBlob(canvas.toDataURL("image/jpeg"));
  return blob;
}
// ###################################################
function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
}
// ###################################################

//##################################################################
function insertaLogos(logos) {
  for (var i in logos) {
    var logo = logos[i].rutaLogo;
    var anchoLogoPorcentaje = logos[i].anchoLogo;
    var margen = logos[i].margen;
    var posicionMarca = logos[i].posicion;
    this.insertaLogo(logo, anchoLogoPorcentaje, margen, posicionMarca);
  }
}

//##################################################################
function insertaLogo(logo, anchoLogoPorcentaje, margen, posicionMarca) {
  // creo la imagen con la ruta recibida
  var img = new Image();
  img.crossOrigin = "";
  img.src = logo;

  // descargo del servidor y calculo ancho y alto.
  img.onload = () => {
    var anchoLogo = img.width;
    var altoLogo = img.height;

    // ajusto tamano en relacion al ancho especificado que ocupara en la foto
    var anchoLogoFinal = this.anchoFoto * anchoLogoPorcentaje;
    var altoLogoFinal = (anchoLogoFinal * altoLogo) / anchoLogo;

    var posX = margen;
    var posY = margen;

    switch (posicionMarca) {
      case 2: // arriba a la derecha
        posX = this.anchoFoto - anchoLogoFinal - margen;
        break;
      case 3: // abajo a la izquierda
        posY = this.altoFoto - altoLogoFinal - margen;
        break;
      case 4: // abajo a la derecha
        posX = this.anchoFoto - anchoLogoFinal - margen;
        posY = this.altoFoto - altoLogoFinal - margen;
        break;
    } // 1 o 0 arriba a la izquierda

    // dibujo la imagen sobre la foto original
    this.ctx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      posX,
      posY,
      anchoLogoFinal,
      altoLogoFinal
    );
  };
}
//##################################################################
function insertaMarco(marco) {
  console.log("marco click" + marco);
  //marco = 'KmetasV4/VistasV4/Configuracion/Imagenes/LogosKmetas/MarcoCTriatlonMerida.png';
  // creo la imagen con la ruta recibida
  var img = new Image();
  img.crossOrigin = "";
  img.src = marco;

  // descargo del servidor y calculo ancho y alto.
  img.onload = () => {
    // dibujo la imagen sobre la foto original
    this.ctx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      0,
      0,
      this.anchoFoto,
      this.altoFoto
    );
    this.insertaLogos();
  };
}
//##################################################################

export async function esFondoClaro(src) {
  return new Promise((resolve, reject) => {
    const img = typeof src === "string" ? new Image() : src;

    if (typeof src === "string") {
      img.crossOrigin = "Anonymous";
      img.src = src;
    }

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      const startX = img.width * 0.1;
      const startY = img.height * 0.25;
      const width = img.width * 0.8;
      const height = img.height * 0.5;

      const imageData = ctx.getImageData(startX, startY, width, height);
      const data = imageData.data;

      let totalLuminancia = 0;
      let totalPixeles = 0;

      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const a = data[i + 3];

        if (a === 0) continue;

        const luminancia = 0.299 * r + 0.587 * g + 0.114 * b;
        totalLuminancia += luminancia;
        totalPixeles++;
      }

      const promedio = totalLuminancia / totalPixeles;
      resolve(promedio > 128); // true si es claro, false si es oscuro
    };

    img.onerror = (err) => {
      reject(err);
    };
  });
}
