import React, { useRef, useEffect } from "react";
import QRCode from "qrcode"; // Librería para generar QR

const QRWithBackground = ({ qrValue, backgroundImage, qrSize = 200 }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.src = backgroundImage;
    image.crossOrigin = "anonymous"; // Evita problemas de CORS

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Generar QR como imagen base64 con color rojo
      QRCode.toDataURL(qrValue, {
        width: qrSize,
        margin: 1,
        color: {
          dark: "#ef4123", // Rojo
          light: "#ffffff", // Blanco de fondo
        },
      })
        .then((qrUrl) => {
          const qrImage = new Image();
          qrImage.src = qrUrl;
          qrImage.onload = () => {
            const posX = (canvas.width - qrSize) / 2;
            const posY = (canvas.height - qrSize) / 2 - 70;
            ctx.drawImage(qrImage, posX, posY, qrSize, qrSize);
          };
        })
        .catch((err) => console.error("Error generando QR:", err));
    };
  }, [qrValue, backgroundImage, qrSize]);

  return <canvas ref={canvasRef} />;
};

export default QRWithBackground;
