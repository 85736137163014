import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import {
  getReadersList,
  updateReaderAttribute,
} from "../../../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function ReaderConfig(props) {
  const { business } = props;
  const [readerMessages, setReaderMessages] = useState(false);

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventResults = useSelector((state) => state.events.eventResults);

  const eventParticipantsData = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventParticipantsRequested = useSelector(
    (state) => state.registry.eventParticipantsRequested
  );
  const eventParticipantsEventIndex = useSelector(
    (state) => state.registry.eventParticipantsEventIndex
  );
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );
  const readersList = useSelector((state) => state.events.readersList);

  const puntosControl = useSelector((state) =>
    state.events.puntosControl.val()
  );

  useEffect(() => {
    dispatch(getReadersList(eventDetail.iDEvento));
  }, []);

  const classes = useStyles();

  function startReading(readerTo) {
    dispatch(updateReaderAttribute(readerTo, { reading: "start" }));
  }
  function stopReading(readerTo) {
    dispatch(updateReaderAttribute(readerTo, { reading: "stop" }));
  }
  function setControlPoint(readerTo, controlPoint) {
    dispatch(updateReaderAttribute(readerTo, { controlPoint: controlPoint }));
  }

  function getMode(readerTo) {
    const topic = readerTo + "/control";
    const json = {
      command: "get_mode",
      command_id: "1",
      payload: {},
    };
    //publish(topic, json);
  }
  function setMode(reader, controlPoint) {
    let antennasArray = [1, 2, 3, 4, 5, 6, 7, 8];
    let antennasPower = [30.0, 30.0, 30.0, 30.0, 30.0, 30.0, 30.0, 30.0];
    if (reader.antennas === 4) {
      antennasArray = [1, 2, 3, 4];
      antennasPower = [30.0, 30.0, 30.0, 30.0];
    }
    const topic = reader.readerId + "/control";
    const json = {
      command: "set_mode",
      command_id: "1",
      payload: {
        antennas: antennasArray,
        filter: {
          match: "prefix",
          operation: "include",
          value: "44415348504F5254",
        },
        reportFilter: { duration: 20, type: "RADIO_WIDE" },
        tagMetaData: [
          { userDefined: eventDetail.iDEvento + "/" + controlPoint + "/true" },
        ],
        transmitPower: antennasPower,
        type: "SIMPLE",
        environment: "HIGH_INTERFERENCE",
      },
    };
    publish(topic, json);
  }
  function setCheckChipMode(reader) {
    const power = 2.0;
    let antennasArray = [1, 2, 3, 4, 5, 6, 7, 8];
    let antennasPower = [
      power,
      power,
      power,
      power,
      power,
      power,
      power,
      power,
    ];
    if (reader.antennas === 4) {
      antennasArray = [1, 2, 3, 4];
      antennasPower = [power, power, power, power];
    }
    const topic = reader.readerId + "/control";
    const json = {
      command: "set_mode",
      command_id: "1",
      payload: {
        antennas: antennasArray,
        filter: {
          match: "prefix",
          operation: "include",
          value: "44415348504F5254",
        },
        reportFilter: { duration: 20, type: "RADIO_WIDE" },
        tagMetaData: [
          { userDefined: eventDetail.iDEvento + "/10000/true/true" },
        ],
        transmitPower: antennasPower,
        type: "SIMPLE",
        environment: "HIGH_INTERFERENCE",
      },
    };
    publish(topic, json);
  }
  function getConfig(readerTo) {
    const topic = readerTo + "/control";
    const json = {
      command: "get_config",
      command_id: "1",
      payload: {},
    };
    publish(topic, json);
  }

  function get_timeZone(readerTo) {
    const topic = readerTo + "/control";
    const json = {
      command: "get_timeZone",
      command_id: "2",
      payload: {},
    };
    publish(topic, json);
  }
  function set_timeZone(readerTo) {
    const topic = readerTo + "/control";
    const json = {
      command: "set_timeZone",
      command_id: "abcd1324",
      payload: {
        timeZone: "International Date Line West",
      },
    };
    publish(topic, json);
  }

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={12}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h1"} component="h2">
              Lectores del evento
            </Typography>{" "}
            {/* 1) lista de readers conectados. tengo que poder poner el id del
            reader 2) Cada Reader debe pedir el mode para tener las antenas
            activas y hora. 3) Botón para iniciar lecturas. */}
          </Grid>
          <Grid item xs={12} style={{ margin: "10px" }}></Grid>

          {readersList &&
            readersList.map((reader) => (
              <Paper style={{ backgroundColor: "#e0e0e0", padding: 30 }}>
                <Grid container direction="row" alignItems="flex-start">
                  <Grid item xs={12} sm={12}>
                    <Typography gutterBottom variant={"h4"} component="h2">
                      Ubicación del lector: {reader.readerConfig.location}
                    </Typography>{" "}
                    <Typography
                      gutterBottom
                      variant={"subtitle1"}
                      component="h2"
                    >
                      Identificador del lector: {reader.readerConfig.readerId}
                    </Typography>{" "}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Button
                      style={{
                        margin: 10,
                        padding: 10,
                        fontSize: 20,
                      }}
                      onClick={() => startReading(reader.readerConfig.readerId)}
                      color="primary"
                      variant="contained"
                      disabled={
                        reader.readerConfig.reading === "start" ? true : false
                      }
                    >
                      {" "}
                      Iniciar Lecturas
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      style={{
                        margin: 10,
                        padding: 10,
                        fontSize: 20,
                      }}
                      onClick={() => stopReading(reader.readerConfig.readerId)}
                      color="primary"
                      variant="contained"
                      disabled={
                        reader.readerConfig.reading === "stop" ? true : false
                      }
                    >
                      {" "}
                      Detener Lecturas
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      style={{ marginTop: 30 }}
                      gutterBottom
                      variant={"h5"}
                      component="h2"
                    >
                      Selecciona el punto de control
                    </Typography>{" "}
                  </Grid>
                  {puntosControl &&
                    Object.values(puntosControl).map((control) => (
                      <Grid item xs={12} sm={3}>
                        <Button
                          style={{
                            margin: 10,
                            padding: 10,
                            fontSize: 20,
                          }}
                          onClick={() =>
                            setControlPoint(
                              reader.readerConfig.readerId,
                              control.kNroPunto
                            )
                          }
                          color="secondary"
                          variant="contained"
                        >
                          {control.descripcionFinalPunto}{" "}
                          {reader.readerConfig.controlPoint ===
                          control.kNroPunto
                            ? "(Seleccionado)"
                            : ""}
                        </Button>
                      </Grid>
                    ))}
                  {/* <Grid item xs={12} sm={6}>
                      <Button
                        style={{
                          margin: 10,
                          padding: 10,
                          fontSize: 20,
                        }}
                        onClick={() => getConfig(reader.readerConfig.readerId)}
                        color="primary"
                        variant="contained"
                      >
                        Obtener Configuración
                      </Button>
                    </Grid> */}
                  {/* <Grid item xs={12} sm={12}>
                      <Button
                        style={{
                          margin: 10,
                          padding: 10,
                          fontSize: 20,
                        }}
                        onClick={() => getMode(reader.readerConfig.readerId)}
                        color="primary"
                        variant="contained"
                      >
                        Obtener Modo de operación del lector
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Button
                        style={{
                          margin: 10,
                          padding: 10,
                          fontSize: 20,
                        }}
                        onClick={() => setCheckChipMode(reader)}
                        color="secondary"
                        variant="contained"
                      >
                        HABILITAR MODO CHECA CHIP
                      </Button>
                    </Grid> */}

                  <Grid item xs={12} sm={12}>
                    {/* <Button
                        style={{
                          margin: 10,
                          padding: 10,
                          fontSize: 20,
                        }}
                        onClick={() => get_timeZone(reader.readerConfig.readerId)}
                        color="primary"
                        variant="contained"
                      >
                        Obtener Zona Horaria
                      </Button>
                      <Button
                        style={{
                          margin: 10,
                          padding: 10,
                          fontSize: 20,
                        }}
                        onClick={() => set_timeZone(reader.readerConfig.readerId)}
                        color="primary"
                        variant="contained"
                      >
                        Definir Zona Horaria
                      </Button> */}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {readerMessages &&
                      readerMessages[reader.readerConfig.readerId] &&
                      JSON.stringify(
                        readerMessages[reader.readerConfig.readerId]
                      )}
                  </Grid>
                </Grid>
              </Paper>
            ))}
          {/* <Grid item xs={12} sm={6} style={{ margin: 30 }}>
            <Button
              style={{ padding: 50, fontSize: 20, width: 500 }}
              onClick={() => configReader()}
              color="primary"
              variant="contained"
            >
              {" "}
              Obtener Config{" "}
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
}
