import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createEventConfig from "../EventCreate/createEventconfig.json";
import {
  createCategory,
  updateEventAttribute,
} from "../../../../../data-store/actions/events-actions";
import EditableField from "../../../../EditableField/EditableField";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function PricingStages(props) {
  const { subEventIndex, subEvent, gender } = props;
  const dispatch = useDispatch();
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const pricingStage = createEventConfig.precioEtapa;

  const classes = useStyles();

  function onSubmit(data) {
    console.log(data);

    dispatch();
    // createCategory(
    //   "evento_" + eventDetail.iDEvento,
    //   data,
    //   userID,
    //   eventSubEventsRegistry,
    //   subEventIndex,
    //   gender
    // )
  }

  function handleChange(value) {
    console.log(value);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "modalidadesInscripcion/" + subEventIndex + "/",
        value
      )
    );
  }

  let stages = eventSubEventsRegistry[subEventIndex].configModalidad.precio
    .etapas
    ? Object.values(
        eventSubEventsRegistry[subEventIndex].configModalidad.precio.etapas
      )
    : [];

  stages.push({
    precio: "",
    desde: "",
  });

  console.log("stages", stages);

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h4"} component="h2">
          Etapas y cambios de precio
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} style={{ margin: "0px" }}>
        {stages.map((a, i) => (
          <Grid
            container
            direction="row"
            style={{
              width: 500,
            }}
          >
            <Grid item xs={12}>
              <Typography gutterBottom variant={"subtitle1"} component="h2">
                {stages.length === i + 1 && "Agregar nueva etapa"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <EditableField
                key={i + "p"}
                fieldObject={{
                  label: "Precio",
                  errorMessage: "Campo Requerido",
                  fieldName:
                    "configModalidad/precio/etapas/etapa_" + i + "/precio",
                  type: "number",
                  defaultValue: a.precio,
                  required: false,
                  regex: "",
                  textSize: "h6",
                  min: 0,
                }}
                onSubmit={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <EditableField
                key={i + "d"}
                fieldObject={{
                  label: "Fecha de inicio",
                  errorMessage: "Campo Requerido",
                  fieldName:
                    "configModalidad/precio/etapas/etapa_" + i + "/desde",
                  type: "date",
                  defaultValue: a.desde,
                  required: false,
                  regex: "",
                  textSize: "h6",
                }}
                onSubmit={handleChange}
              />
            </Grid>
          </Grid>
        ))}
        {/* <SimpleDialogDemo buttonText={"Crear Precio"}>
          <div style={{ margin: 30 }}>
            <FormRender
              callback={onSubmit}
              formObject={pricingStage}
              submitButtonText={"Crear precio"}
            />
          </div>
        </SimpleDialogDemo> */}
      </Grid>
      <Grid item xs={12} sm={12} style={{ margin: "0px" }}>
        <Typography gutterBottom variant={"subtitle1"} component="h2">
          NOTA: los cambios de precios definidos para cada día se procesan a las
          2am hora de México
        </Typography>
      </Grid>
    </Grid>
  );
}
