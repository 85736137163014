import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Axios from "axios";
import https from "https";
import ReaderConfigMQTT from "./ReaderConfigMQTT";

import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";
import CheckChip from "./CheckChip";
import { updateAthleteResult } from "../../../../../data-store/actions/events-actions";
import jsonTags from "./jsonTags.json";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function ReaderConfigLocal(props) {
  const { business } = props;
  const [ipReader, setIpReader] = useState("192.168.2.2");
  const [reading, setReading] = useState(false);
  const [postMessage, setPostMessage] = useState("");
  const [modeMessage, setModeMessage] = useState("");

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventResults = useSelector((state) => state.events.eventResults);

  const eventParticipantsData = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventParticipantsRequested = useSelector(
    (state) => state.registry.eventParticipantsRequested
  );
  const eventParticipantsEventIndex = useSelector(
    (state) => state.registry.eventParticipantsEventIndex
  );
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );

  const puntosControl = useSelector((state) =>
    state.events.puntosControl.val()
  );

  useEffect(() => {
    if (
      !eventParticipantsRequested ||
      eventParticipantsEventIndex !== "evento_" + eventDetail.iDEvento
    ) {
      dispatch(eventParticipants("evento_" + eventDetail.iDEvento));
    }
  }, []);

  const classes = useStyles();
  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJaRUJSQSIsImV4cCI6MTY2NzAwMTA2MX0.1k4cOLuNacbI7nDyHe0V2FuHkvy75a45UQkVyv6t4sQdhZHowcjbJPEd-pFBNuxqtxCYCC429P6JS6F2o8HYkA";

  const filter = Object.values(jsonTags).filter(
    (a) => a.tag === "44415348504F525430333437"
  );
  console.log(
    "arrayTranslateChipRaceResultToDashport",
    filter[0].chipNumber,
    filter.length
  );

  function hex_to_ascii(str1) {
    var hex = str1.toString();
    var str = "";
    for (var n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    console.log();
    return str;
  }
  // DASHPORT HEX 44415348504f5254
  console.log(hex_to_ascii("44415348504f525430343034"));

  const date = "2022-10-29T00:55:04.178+0000";
  const fechaHoraSplit = date.split("T");
  const fechaSplit = fechaHoraSplit[0].split("-");
  const year = fechaSplit[0];
  const month = fechaSplit[1];
  const day = fechaSplit[2];
  const hora = fechaHoraSplit[1].split("+")[0];
  const horaSplit = hora.split(":");
  const hourNew =
    horaSplit[0] +
    ":" +
    horaSplit[1] +
    ":" +
    horaSplit[2].split(".")[0] +
    "." +
    horaSplit[2].split(".")[1];

  var fechaHora = year + "-" + month + "-" + day + " " + hourNew;
  console.log("fechaHora", fechaHora);

  // function startReading() {
  //   var config = {
  //     method: "put",
  //     url: "https://192.168.2.2/cloud/start",
  //     headers: {
  //       Authorization: "Bearer " + token,
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Headers": "*",
  //     },
  //   };

  //   Axios(config)
  //     .then(function(response) {
  //       console.log(JSON.stringify(response.data));
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // }

  // function logIn() {
  //   var config = {
  //     method: "get",
  //     url: "https://fx96005fa548.local/cloud/localRestLogin",
  //     headers: {
  //       Authorization: "Basic YWRtaW46U3BvcnQxQFplYnJh",
  //       "Access-Control-Allow-Origin": true,
  //       "Access-Control-Allow-Headers": true,
  //     },
  //     httpsAgent: new https.Agent({ rejectUnauthorized: false }),
  //   };

  //   Axios(config)
  //     .then(function(response) {
  //       console.log(JSON.stringify(response.data));
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // }
  // function stopReading() {
  //   console.log("response");
  //   Axios({
  //     url: "https://" + ipReader + "/cloud/start", //your url
  //     method: "PUT",
  //   }).then((response) => {
  //     console.log("response", response);
  //   });
  // }

  //  return <CheckChip />;

  const reader7500 = {
    reader: "fx75007441b0.local",
    auth: "YWRtaW46eERCdkREZHNrZmxtOT8=",
  };
  const reader9600 = {
    reader: "fx96005fa548.local",
    auth: "YWRtaW46U3BvcnQxQFplYnJh",
  };
  const reader96008p = {
    reader: "fx9600760866.local",
    auth: "YWRtaW46eERCdkREZHNrZmxtOT8=",
  };
  const reader9600Dashport = {
    reader: "fx9600f54bd7.local",
    auth: "YWRtaW46U3BvcnQxQFplYnJh",
  };

  const reader = reader9600Dashport;

  function startNew() {
    // metodo de prueba para hacer peticiones desde la web directo al lector
    console.log("start");

    var config = {
      method: "get",
      url: "https://" + reader.reader + "/cloud/localRestLogin",

      headers: {
        Authorization: "Basic " + reader.auth,
      },
      httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    };

    Axios(config)
      .then(function(responseLog) {
        console.log(JSON.stringify(responseLog.data));

        var config = {
          method: "put",
          url: "https://" + reader.reader + "/cloud/start",
          headers: {
            Authorization: "Bearer " + responseLog.data.message,
          },
          httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        };

        Axios(config)
          .then(function(response) {
            console.log(JSON.stringify(response.data));
            console.log("reading");
          })
          .catch(function(error) {
            console.log(error);
            console.log(error);
          });
      })
      .catch(function(error) {
        console.log(JSON.stringify(error));
        console.log(error);
      });
  }
  function startReadingLocal() {
    var config = {
      method: "get",
      url: "http://localhost:3000/start",
    };

    Axios(config)
      .then(function(response) {
        console.log(JSON.stringify(response.data));
        console.log("reading");
        setReading(true);
        setPostMessage(JSON.stringify(response.data));
      })
      .catch(function(error) {
        console.log(error);
        setPostMessage(JSON.stringify(error));
      });
  }

  function stopReadingLocal() {
    var config = {
      method: "get",
      url: "http://localhost:3000/stop",
    };

    Axios(config)
      .then(function(response) {
        console.log(JSON.stringify(response.data));
        console.log("lecturas detenidas");

        setReading(false);
        setPostMessage(JSON.stringify(response.data));
      })
      .catch(function(error) {
        setPostMessage(JSON.stringify(error));
        console.log(error);
      });
  }
  function configReaderLocal() {
    var config = {
      method: "get",
      url: "http://localhost:3000/config",
    };

    Axios(config)
      .then(function(response) {
        console.log("config1", JSON.stringify(response.data));
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  // function cambiarNombres() {
  //   console.log("Cambiar Nombres");
  //   for (var i in eventResults) {
  //     if (data[eventResults[i].num]) {
  //       console.log(eventResults[i].num, " - ", data[eventResults[i].num]);

  //       dispatch(
  //         updateAthleteResult(
  //           "evento_" + eventDetail.iDEvento,
  //           eventResults[i].num,
  //           { nombre: data[eventResults[i].num] }
  //         )
  //       );
  //     }
  //   }
  // }

  function hex_to_ascii(str1) {
    var hex = str1.toString();
    var str = "";
    for (var n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
  }

  function ascii_to_hex(str) {
    var hex = "";
    for (var i = 0; i < str.length; i++) {
      hex += str
        .charCodeAt(i)
        .toString(16)
        .padStart(2, "0");
    }
    return hex;
  }

  // Ejemplo de uso:
  console.log(ascii_to_hex("Hola")); // 486f6c61

  function getMode() {
    var config = {
      method: "get",
      url: "http://localhost:3000/getMode",
    };

    Axios(config)
      .then(function(response) {
        console.log(JSON.stringify(response.data));
        console.log("Modo obtenido");

        setModeMessage(JSON.stringify(response.data));
      })
      .catch(function(error) {
        setModeMessage(JSON.stringify(error));
        console.log(error);
      });
  }

  function setMode(reader, controlPoint) {
    let antennasArray = [1, 2, 3, 4, 5, 6, 7, 8];
    let antennasPower = [30.0, 30.0, 30.0, 30.0, 30.0, 30.0, 30.0, 30.0];
    if (reader.antennas === 4) {
      antennasArray = [1, 2, 3, 4];
      antennasPower = [30.0, 30.0, 30.0, 30.0];
    }
    const json = {
      command: "set_mode",
      command_id: "1",
      payload: {
        antennas: antennasArray,
        filter: {
          match: "prefix",
          operation: "include",
          value: "44415348504F5254",
        },
        reportFilter: { duration: 20, type: "RADIO_WIDE" },
        tagMetaData: [
          { userDefined: eventDetail.iDEvento + "/" + controlPoint + "/true" },
        ],
        transmitPower: antennasPower,
        type: "SIMPLE",
        environment: "HIGH_INTERFERENCE",
      },
    };
    var config = {
      method: "get",
      url: "http://localhost:3000/putMode",
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": true,
      },
      data: json,
    };

    Axios(config)
      .then(function(response) {
        console.log(JSON.stringify(response.data));
        console.log("Modo obtenido");

        setModeMessage(JSON.stringify(response.data));
      })
      .catch(function(error) {
        setModeMessage(JSON.stringify(error));
        console.log(error);
      });
  }

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={12}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h1"} component="h2">
              Reader Config --
            </Typography>{" "}
            <Typography gutterBottom variant={"h1"} component="h2">
              {hex_to_ascii("44415348504f525430353737")}
            </Typography>{" "}
            <Typography gutterBottom variant={"h1"} component="h2">
              {ascii_to_hex("DASHPORT0577")}
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              style={{ margin: 30, padding: 20, fontSize: 20, width: 200 }}
              onClick={() => startReadingLocal()}
              color="primary"
              variant="contained"
            >
              {" "}
              Iniciar Lecturas{" "}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              style={{ margin: 30, padding: 20, fontSize: 20, width: 200 }}
              onClick={() => stopReadingLocal()}
              color="primary"
              variant="contained"
            >
              {" "}
              Detener Lecturas{" "}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              style={{ marginTop: 30 }}
              gutterBottom
              variant={"h5"}
              component="h2"
            >
              Selecciona el punto de control
            </Typography>{" "}
          </Grid>

          {puntosControl &&
            Object.values(puntosControl).map((control) => (
              <Grid item xs={12} sm={3}>
                <Button
                  style={{
                    margin: 10,
                    padding: 10,
                    fontSize: 20,
                  }}
                  onClick={() => setMode(reader, control.kNroPunto)}
                  color="secondary"
                  variant="contained"
                >
                  {control.descripcionFinalPunto}
                </Button>
              </Grid>
            ))}

          <Grid item xs={12} sm={12}>
            <Button
              style={{ margin: 30, padding: 20, fontSize: 20, width: 200 }}
              onClick={() => getMode()}
              color="primary"
              variant="contained"
            >
              {" "}
              Obtener modo de operación{" "}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: 30 }}>
            <Typography gutterBottom variant={"h1"} component="h2">
              {/* {reading && "Lecturas iniciadas"}
              {!reading && "Lecturas detenidas"} */}
              {postMessage}
            </Typography>{" "}
            <Typography gutterBottom variant={"h1"} component="h5">
              {/* {reading && "Lecturas iniciadas"}
              {!reading && "Lecturas detenidas"} */}
              {modeMessage}
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={6} style={{ margin: 30 }}>
            {/* <Button
              style={{ padding: 50, fontSize: 20, width: 500 }}
              onClick={() => configReaderLocal()}
              color="primary"
              variant="contained"
            >
              {" "}
              Obtener Config{" "}
            </Button> */}
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: 0 }}>
            {salidas.map((a, i) => {
              return (
                <Grid container direction="row" alignItems="flex-start">
                  <Grid item xs={12} sm={6} style={{ margin: 0 }}>
                    {hex_to_ascii(a.data.idHex)}
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ margin: 0 }}>
                    Salida: {a.timestamp}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        {/* <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={2}>
            <Typography align="center" variant={"h6"} component="h2">
              Número
            </Typography>{" "}
          </Grid>
          <Grid item xs={2}>
            <Typography align="center" variant={"h6"} component="h2">
              Salida chip {getListRegistries().totales.salidaChip}
            </Typography>{" "}
          </Grid>
          <Grid item xs={2}>
            <Typography align="center" variant={"h6"} component="h2">
              Meta chip {getListRegistries().totales.metaChip}
            </Typography>{" "}
          </Grid>
          <Grid item xs={2}>
            <Typography align="center" variant={"h6"} component="h2">
              Meta Manual {getListRegistries().totales.metaJuez}
            </Typography>{" "}
          </Grid>
          <Grid item xs={2}>
            <Typography align="center" variant={"h6"} component="h2">
              Chip salida y meta
            </Typography>{" "}
          </Grid>
        </Grid>

        {getListRegistries().number.map((a, i) => (
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            style={{ backgroundColor: i % 2 === 0 ? "white" : "#e0e0e0" }}
          >
            <Grid item xs={2}>
              <Typography align="center" variant={"h6"} component="h2">
                {a.num}
              </Typography>{" "}
            </Grid>
            <Grid item xs={2}>
              <Typography align="center" variant={"h6"} component="h2">
                {a.salidaChip}
              </Typography>{" "}
            </Grid>
            <Grid item xs={2}>
              <Typography align="center" variant={"h6"} component="h2">
                {a.metaChip}
              </Typography>{" "}
            </Grid>
            <Grid item xs={2}>
              <Typography align="center" variant={"h6"} component="h2">
                {a.metaJuez}
              </Typography>{" "}
            </Grid>
            <Grid item xs={2}>
              <Typography align="center" variant={"h6"} component="h2">
                {a.chipOk}
              </Typography>{" "}
            </Grid>
          </Grid>
        ))} */}

        {/* <Button
          style={{ padding: 50, fontSize: 20, width: 500 }}
          onClick={() => cambiarNombres()}
          color="primary"
          variant="contained"
        >
          {" "}
          Cambiar nombres
        </Button> */}

        {/* <CheckChip /> */}
      </Grid>
    </Grid>
  );
}

const arrayTranslateChipRaceResultToDashport = {
  num_e28011606000020a6683f371: 1,
  num_e28011606000020a668229fb: 2,
  num_e28011606000020a668255a1: 3,
  num_e28011606000020a6683f3a3: 4,
  num_e28011606000020a66830bc1: 5,
  num_e28011606000020a66830b20: 6,
  num_e28011606000020a66899bcb: 7,
};
const arrayTranslateChipRaceResultToDashport2 = {
  num_e28011606000020a66887c1d: 700,
  num_e28011606000020a66826ca9: 701,
  num_e28011606000020a6681f383: 702,
  num_e28011606000020a66830ba1: 703,
  num_e28011606000020a6688ed02: 704,
  num_e28011606000020a66893bd1: 705,
  num_e28011606000020a66893bb1: 706,
};
const data = {
  801: `MANUEL JESUS PINTO ARAUJO - ELOY GUZMAN - SARA RENSHAW - FERNANDO MARTIN - MARINA RIBEIRO`,
  802: `RAUL EDUARDO CORREA - MARISOL  CABRERA LAGUNES - RAÚL ALEJANDRO CORREA CABRERA - CRISTIAN  CARDONA RIVERA - CARINA CANTÚ HERNANDEZ`,
  803: `KARINA VIVIANA PALOMO MÉNDEZ - PATRICIA REYES PEÓN - JAIME JOSÉ GUILLERMO PEÓN - ANA LUISA SOBRINO ACOSTA - JOSÉ ERNESTO CERVERA SEGURA`,
  806: `JULIANA DIEZ - SANDRA  CARDENAS - JERSON CUA RAFFUL - JOSE HERNANDEZ - FERNANDO  ECHAVARRIA`,
  807: `ANA LAURA BORGES MIS - LUIS FERNANDO CAUICH CRIOLLO - ARTURO SEBASTIAN JIMENEZ CHALE - FRANCISCO VICENTE HUEZO PEON - RICARDO ALEJANDRO REJON HERRERA`,
  808: `MELISSA LAGUNA - JAIME ENRIQUE REJON AKE - GIMMY SANCHEZ GARCIA - CARLOS PACHECO POOT - LENNY SUSANA BARRERA BLANCO`,
  809: `ANA DANIELA  MARRUFO HERRERA - VICTOR MUNGUIA SAAVEDRA - JENSY  ARCEO ROJINA - JERONIMO MUNGUIA ZARCO - ANDRES  MUNGUIA ZARCO`,
};

const salidas = [
  {
    data: {
      eventNum: 100,
      format: "epc",
      idHex: "44415348504f525430353737",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:21.099-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1000,
      format: "epc",
      idHex: "44415348504f525430353539",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:32.306-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1001,
      format: "epc",
      idHex: "44415348504f525430313537",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:33.721-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1002,
      format: "epc",
      idHex: "44415348504f525430363533",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:33.772-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1003,
      format: "epc",
      idHex: "44415348504f525430313133",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:35.465-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1004,
      format: "epc",
      idHex: "44415348504f525430313134",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:35.550-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1005,
      format: "epc",
      idHex: "44415348504f525430313136",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:43.889-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1006,
      format: "epc",
      idHex: "44415348504f525430393834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:49.150-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1007,
      format: "epc",
      idHex: "44415348504f525431303030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:50.795-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1008,
      format: "epc",
      idHex: "44415348504f525430313939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:50.966-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1009,
      format: "epc",
      idHex: "44415348504f525430313431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:51.054-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 101,
      format: "epc",
      idHex: "44415348504f525431313537",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:21.176-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 102,
      format: "epc",
      idHex: "44415348504f525430313531",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:21.180-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1010,
      format: "epc",
      idHex: "44415348504f525430323936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:51.201-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1011,
      format: "epc",
      idHex: "44415348504f525430323032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:51.235-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1012,
      format: "epc",
      idHex: "44415348504f525430353233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:53.686-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1013,
      format: "epc",
      idHex: "44415348504f525430333031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:53.711-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1014,
      format: "epc",
      idHex: "44415348504f525430343938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:53.730-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1015,
      format: "epc",
      idHex: "44415348504f525430373432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:57.794-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1016,
      format: "epc",
      idHex: "44415348504f525430323031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:59.605-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1017,
      format: "epc",
      idHex: "44415348504f525430363333",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:00.318-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1018,
      format: "epc",
      idHex: "44415348504f525430353235",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:01.500-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1019,
      format: "epc",
      idHex: "44415348504f525430393834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:09.259-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1020,
      format: "epc",
      idHex: "44415348504f525430313939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:10.993-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1021,
      format: "epc",
      idHex: "44415348504f525430323936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:11.310-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1022,
      format: "epc",
      idHex: "44415348504f525430323032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:11.486-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1023,
      format: "epc",
      idHex: "44415348504f525430313431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:11.654-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1024,
      format: "epc",
      idHex: "44415348504f525430303231",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:12.811-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1025,
      format: "epc",
      idHex: "44415348504f525430353233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:13.755-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1026,
      format: "epc",
      idHex: "44415348504f525430343938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:13.787-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1027,
      format: "epc",
      idHex: "44415348504f525430373432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:17.920-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1028,
      format: "epc",
      idHex: "44415348504f525430333031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:19.312-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1029,
      format: "epc",
      idHex: "44415348504f525430323031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:19.700-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 103,
      format: "epc",
      idHex: "44415348504f525431303130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:21.404-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1030,
      format: "epc",
      idHex: "44415348504f525430393834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:29.321-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1031,
      format: "epc",
      idHex: "44415348504f525430313939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:31.074-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1032,
      format: "epc",
      idHex: "44415348504f525430323032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:31.616-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1033,
      format: "epc",
      idHex: "44415348504f525430323936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:32.458-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1034,
      format: "epc",
      idHex: "44415348504f525430303231",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:33.241-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1035,
      format: "epc",
      idHex: "44415348504f525430353233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:33.793-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1036,
      format: "epc",
      idHex: "44415348504f525430343938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:33.809-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1037,
      format: "epc",
      idHex: "44415348504f525431303030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:36.026-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1038,
      format: "epc",
      idHex: "44415348504f525430313431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:36.210-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1039,
      format: "epc",
      idHex: "44415348504f525430373432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:38.301-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 104,
      format: "epc",
      idHex: "44415348504f525430373337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:21.452-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1040,
      format: "epc",
      idHex: "44415348504f525430333031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:39.387-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1041,
      format: "epc",
      idHex: "44415348504f525430393834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:49.351-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1042,
      format: "epc",
      idHex: "44415348504f525430313939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:51.114-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1043,
      format: "epc",
      idHex: "44415348504f525430323031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:51.604-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1044,
      format: "epc",
      idHex: "44415348504f525430323032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:52.122-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1045,
      format: "epc",
      idHex: "44415348504f525430323936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:52.470-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1046,
      format: "epc",
      idHex: "44415348504f525430353233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:53.811-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1047,
      format: "epc",
      idHex: "44415348504f525430343938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:53.857-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1048,
      format: "epc",
      idHex: "44415348504f525431303030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:56.038-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1049,
      format: "epc",
      idHex: "44415348504f525430313431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:56.665-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 105,
      format: "epc",
      idHex: "44415348504f525430353032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:21.509-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1050,
      format: "epc",
      idHex: "44415348504f525430373432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:42:58.316-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1051,
      format: "epc",
      idHex: "44415348504f525430333031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:02.173-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1052,
      format: "epc",
      idHex: "44415348504f525430393834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:09.405-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1053,
      format: "epc",
      idHex: "44415348504f525430313939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:11.170-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1054,
      format: "epc",
      idHex: "44415348504f525430323032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:12.234-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1055,
      format: "epc",
      idHex: "44415348504f525430323936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:12.495-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1056,
      format: "epc",
      idHex: "44415348504f525430343938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:13.894-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1057,
      format: "epc",
      idHex: "44415348504f525430353233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:13.915-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1058,
      format: "epc",
      idHex: "44415348504f525431303030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:16.636-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1059,
      format: "epc",
      idHex: "44415348504f525430373432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:18.446-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 106,
      format: "epc",
      idHex: "44415348504f525430313234",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:21.722-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1060,
      format: "epc",
      idHex: "44415348504f525430323031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:21.634-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1061,
      format: "epc",
      idHex: "44415348504f525430313431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:21.911-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1062,
      format: "epc",
      idHex: "44415348504f525430393834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:29.423-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1063,
      format: "epc",
      idHex: "44415348504f525430313939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:31.213-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1064,
      format: "epc",
      idHex: "44415348504f525430343938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:33.903-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1065,
      format: "epc",
      idHex: "44415348504f525430353233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:34.432-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1066,
      format: "epc",
      idHex: "44415348504f525431303030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:36.926-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1067,
      format: "epc",
      idHex: "44415348504f525430323936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:37.038-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1068,
      format: "epc",
      idHex: "44415348504f525430323032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:37.424-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1069,
      format: "epc",
      idHex: "44415348504f525430363333",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:37.695-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 107,
      format: "epc",
      idHex: "44415348504f525430323538",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:21.923-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1070,
      format: "epc",
      idHex: "44415348504f525430333031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:37.961-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1071,
      format: "epc",
      idHex: "44415348504f525430373133",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:38.076-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1072,
      format: "epc",
      idHex: "44415348504f525430373432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:38.554-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1073,
      format: "epc",
      idHex: "44415348504f525430303231",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:38.590-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1074,
      format: "epc",
      idHex: "44415348504f525431303833",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:38.903-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1075,
      format: "epc",
      idHex: "44415348504f525430363632",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:38.920-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1076,
      format: "epc",
      idHex: "44415348504f525430383431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:39.159-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1077,
      format: "epc",
      idHex: "44415348504f525430363434",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:39.203-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1078,
      format: "epc",
      idHex: "44415348504f525430313733",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:39.245-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1079,
      format: "epc",
      idHex: "44415348504f525430343133",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:39.312-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 108,
      format: "epc",
      idHex: "44415348504f525430353332",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:21.998-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1080,
      format: "epc",
      idHex: "44415348504f525430373834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:39.610-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1081,
      format: "epc",
      idHex: "44415348504f525430383939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:40.120-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1082,
      format: "epc",
      idHex: "44415348504f525430333036",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:40.184-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1083,
      format: "epc",
      idHex: "44415348504f525430343036",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:40.283-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1084,
      format: "epc",
      idHex: "44415348504f525430383133",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:40.353-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1085,
      format: "epc",
      idHex: "44415348504f525430363736",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:40.689-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1086,
      format: "epc",
      idHex: "44415348504f525430323033",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:41.309-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1087,
      format: "epc",
      idHex: "44415348504f525430303639",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:41.367-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1088,
      format: "epc",
      idHex: "44415348504f525430373830",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:41.419-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1089,
      format: "epc",
      idHex: "44415348504f525430313833",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:41.620-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 109,
      format: "epc",
      idHex: "44415348504f525430343030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:22.033-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1090,
      format: "epc",
      idHex: "44415348504f525430383138",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:41.638-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1091,
      format: "epc",
      idHex: "44415348504f525430373836",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:41.659-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1092,
      format: "epc",
      idHex: "44415348504f525430323031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:41.683-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1093,
      format: "epc",
      idHex: "44415348504f525430343230",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:41.823-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1094,
      format: "epc",
      idHex: "44415348504f525430333234",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:41.917-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1095,
      format: "epc",
      idHex: "44415348504f525430353534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:42.144-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1096,
      format: "epc",
      idHex: "44415348504f525431303536",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:42.151-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1097,
      format: "epc",
      idHex: "44415348504f525430383234",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:42.640-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1098,
      format: "epc",
      idHex: "44415348504f525431313635",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:43.042-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1099,
      format: "epc",
      idHex: "44415348504f525430363134",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:43.078-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 110,
      format: "epc",
      idHex: "44415348504f525430343738",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:22.067-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 111,
      format: "epc",
      idHex: "44415348504f525431303432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:22.161-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 112,
      format: "epc",
      idHex: "44415348504f525430393633",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:22.215-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1100,
      format: "epc",
      idHex: "44415348504f525430353136",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:43.270-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1101,
      format: "epc",
      idHex: "44415348504f525431303238",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:43.779-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1102,
      format: "epc",
      idHex: "44415348504f525430333434",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:43.787-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1103,
      format: "epc",
      idHex: "44415348504f525430323431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:44.012-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1104,
      format: "epc",
      idHex: "44415348504f525430383237",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:44.031-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1105,
      format: "epc",
      idHex: "44415348504f525430383538",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:44.048-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1106,
      format: "epc",
      idHex: "44415348504f525430333837",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:44.132-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1107,
      format: "epc",
      idHex: "44415348504f525430363937",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:44.243-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1108,
      format: "epc",
      idHex: "44415348504f525430383239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:44.296-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1109,
      format: "epc",
      idHex: "44415348504f525431303834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:44.316-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1110,
      format: "epc",
      idHex: "44415348504f525430383333",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:44.573-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1111,
      format: "epc",
      idHex: "44415348504f525430383335",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:44.989-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1112,
      format: "epc",
      idHex: "44415348504f525430323834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:45.058-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1113,
      format: "epc",
      idHex: "44415348504f525430383432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:45.492-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1114,
      format: "epc",
      idHex: "44415348504f525430323435",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:45.746-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1115,
      format: "epc",
      idHex: "44415348504f525431303733",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:45.982-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1116,
      format: "epc",
      idHex: "44415348504f525430353730",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:46.073-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1117,
      format: "epc",
      idHex: "44415348504f525431303738",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:46.291-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1118,
      format: "epc",
      idHex: "44415348504f525430313239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:46.663-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1119,
      format: "epc",
      idHex: "44415348504f525430353731",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:46.807-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1120,
      format: "epc",
      idHex: "44415348504f525430393438",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:47.341-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1121,
      format: "epc",
      idHex: "44415348504f525430383835",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:47.345-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1122,
      format: "epc",
      idHex: "44415348504f525430383235",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:47.540-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1123,
      format: "epc",
      idHex: "44415348504f525430383735",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:47.586-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1124,
      format: "epc",
      idHex: "44415348504f525430333030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:47.612-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1125,
      format: "epc",
      idHex: "44415348504f525430383236",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:47.721-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1126,
      format: "epc",
      idHex: "44415348504f525431303031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:47.801-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1127,
      format: "epc",
      idHex: "44415348504f525430323333",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:47.894-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1128,
      format: "epc",
      idHex: "44415348504f525430373538",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:47.918-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1129,
      format: "epc",
      idHex: "44415348504f525430303038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:48.825-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 113,
      format: "epc",
      idHex: "44415348504f525430343237",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:22.248-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1130,
      format: "epc",
      idHex: "44415348504f525430303735",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:49.376-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1131,
      format: "epc",
      idHex: "44415348504f525430323230",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:49.389-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1132,
      format: "epc",
      idHex: "44415348504f525430353839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:49.777-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1133,
      format: "epc",
      idHex: "44415348504f525430373734",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:49.838-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1134,
      format: "epc",
      idHex: "44415348504f525430303736",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:49.843-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1135,
      format: "epc",
      idHex: "44415348504f525430353838",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:49.855-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1136,
      format: "epc",
      idHex: "44415348504f525430323530",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:49.977-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1137,
      format: "epc",
      idHex: "44415348504f525430393231",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:50.035-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1138,
      format: "epc",
      idHex: "44415348504f525430303933",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:50.361-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1139,
      format: "epc",
      idHex: "44415348504f525430323232",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:50.620-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 114,
      format: "epc",
      idHex: "44415348504f525430373831",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:23.021-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1140,
      format: "epc",
      idHex: "44415348504f525431303138",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:50.679-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1141,
      format: "epc",
      idHex: "44415348504f525430333734",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:50.796-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1142,
      format: "epc",
      idHex: "44415348504f525430353535",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:51.526-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1143,
      format: "epc",
      idHex: "44415348504f525431303139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:51.679-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1144,
      format: "epc",
      idHex: "44415348504f525430393332",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:51.948-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1145,
      format: "epc",
      idHex: "44415348504f525430393337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:52.324-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1146,
      format: "epc",
      idHex: "44415348504f525430323438",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:52.341-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1147,
      format: "epc",
      idHex: "44415348504f525431303037",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:52.875-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1148,
      format: "epc",
      idHex: "44415348504f525430383233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:53.137-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1149,
      format: "epc",
      idHex: "44415348504f525430323938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:53.170-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1150,
      format: "epc",
      idHex: "44415348504f525430333732",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:53.295-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 115,
      format: "epc",
      idHex: "44415348504f525430393431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:23.046-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1151,
      format: "epc",
      idHex: "44415348504f525430353134",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:53.933-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1152,
      format: "epc",
      idHex: "44415348504f525430323637",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:53.961-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1153,
      format: "epc",
      idHex: "44415348504f525431303435",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:53.997-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1154,
      format: "epc",
      idHex: "44415348504f525430363639",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:54.469-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1155,
      format: "epc",
      idHex: "44415348504f525430313634",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:54.859-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1156,
      format: "epc",
      idHex: "44415348504f525431313530",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:55.046-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1157,
      format: "epc",
      idHex: "44415348504f525430383037",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:55.596-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1158,
      format: "epc",
      idHex: "44415348504f525430363938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:55.787-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1159,
      format: "epc",
      idHex: "44415348504f525430303633",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:55.791-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1160,
      format: "epc",
      idHex: "44415348504f525430333035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:55.880-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 116,
      format: "epc",
      idHex: "44415348504f525430373135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:23.050-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 117,
      format: "epc",
      idHex: "44415348504f525430383836",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:23.228-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1161,
      format: "epc",
      idHex: "44415348504f525430323732",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:55.960-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1162,
      format: "epc",
      idHex: "44415348504f525430303330",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:56.044-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1163,
      format: "epc",
      idHex: "44415348504f525430323739",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:56.286-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1164,
      format: "epc",
      idHex: "44415348504f525430323730",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:56.837-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1165,
      format: "epc",
      idHex: "44415348504f525430323833",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:57.130-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1166,
      format: "epc",
      idHex: "44415348504f525430323134",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:57.285-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1167,
      format: "epc",
      idHex: "44415348504f525430323733",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:57.319-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1168,
      format: "epc",
      idHex: "44415348504f525430363930",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:57.555-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1169,
      format: "epc",
      idHex: "44415348504f525430363839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:57.573-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1170,
      format: "epc",
      idHex: "44415348504f525430313930",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:57.835-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1171,
      format: "epc",
      idHex: "44415348504f525430313838",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:57.880-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1172,
      format: "epc",
      idHex: "44415348504f525430323731",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:58.031-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1173,
      format: "epc",
      idHex: "44415348504f525430383634",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:58.118-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1174,
      format: "epc",
      idHex: "44415348504f525430373838",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:58.126-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1175,
      format: "epc",
      idHex: "44415348504f525430393532",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:58.324-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1176,
      format: "epc",
      idHex: "44415348504f525431313333",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:59.006-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1177,
      format: "epc",
      idHex: "44415348504f525430393534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:59.041-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1178,
      format: "epc",
      idHex: "44415348504f525430323433",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:59.105-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1179,
      format: "epc",
      idHex: "44415348504f525430323830",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:59.153-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 118,
      format: "epc",
      idHex: "44415348504f525430323135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:23.483-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1180,
      format: "epc",
      idHex: "44415348504f525430373330",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:59.293-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1181,
      format: "epc",
      idHex: "44415348504f525430333932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:43:59.744-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1182,
      format: "epc",
      idHex: "44415348504f525430373735",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:00.132-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1183,
      format: "epc",
      idHex: "44415348504f525430353633",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:00.167-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1184,
      format: "epc",
      idHex: "44415348504f525430373239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:00.835-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1185,
      format: "epc",
      idHex: "44415348504f525430323832",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:01.056-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1186,
      format: "epc",
      idHex: "44415348504f525430323237",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:01.717-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1187,
      format: "epc",
      idHex: "44415348504f525430373235",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:02.154-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1188,
      format: "epc",
      idHex: "44415348504f525430393032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:02.158-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1189,
      format: "epc",
      idHex: "44415348504f525430333635",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:02.595-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 119,
      format: "epc",
      idHex: "44415348504f525430323738",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:23.739-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1190,
      format: "epc",
      idHex: "44415348504f525430323439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:02.660-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1191,
      format: "epc",
      idHex: "44415348504f525430363537",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:02.706-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1192,
      format: "epc",
      idHex: "44415348504f525431303731",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:02.718-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1193,
      format: "epc",
      idHex: "44415348504f525430383732",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:03.275-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1194,
      format: "epc",
      idHex: "44415348504f525430353033",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:03.824-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1195,
      format: "epc",
      idHex: "44415348504f525430393731",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:03.931-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1196,
      format: "epc",
      idHex: "44415348504f525430393733",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:03.939-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1197,
      format: "epc",
      idHex: "44415348504f525430373737",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:04.010-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1198,
      format: "epc",
      idHex: "44415348504f525430393732",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:04.044-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1199,
      format: "epc",
      idHex: "44415348504f525430393033",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:04.487-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 120,
      format: "epc",
      idHex: "44415348504f525430383537",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:24.084-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1200,
      format: "epc",
      idHex: "44415348504f525430363233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:04.780-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1201,
      format: "epc",
      idHex: "44415348504f525431303630",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:04.888-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1202,
      format: "epc",
      idHex: "44415348504f525430383931",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:04.949-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1203,
      format: "epc",
      idHex: "44415348504f525430323437",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:04.952-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1204,
      format: "epc",
      idHex: "44415348504f525430393236",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:05.363-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1205,
      format: "epc",
      idHex: "44415348504f525430373738",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:05.574-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1206,
      format: "epc",
      idHex: "44415348504f525430383332",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:05.636-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1207,
      format: "epc",
      idHex: "44415348504f525430383631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:06.037-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1208,
      format: "epc",
      idHex: "44415348504f525430333839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:06.490-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1209,
      format: "epc",
      idHex: "44415348504f525430333835",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:06.781-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 121,
      format: "epc",
      idHex: "44415348504f525430373435",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:24.163-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 122,
      format: "epc",
      idHex: "44415348504f525430343136",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:24.176-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1210,
      format: "epc",
      idHex: "44415348504f525430323639",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:07.169-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1211,
      format: "epc",
      idHex: "44415348504f525430333334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:07.338-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1212,
      format: "epc",
      idHex: "44415348504f525430373839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:07.391-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1213,
      format: "epc",
      idHex: "44415348504f525430373639",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:07.702-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1214,
      format: "epc",
      idHex: "44415348504f525430373632",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:08.315-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1215,
      format: "epc",
      idHex: "44415348504f525430373438",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:08.900-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1216,
      format: "epc",
      idHex: "44415348504f525430373937",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:08.966-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1217,
      format: "epc",
      idHex: "44415348504f525430393635",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:09.002-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1218,
      format: "epc",
      idHex: "44415348504f525430383731",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:09.377-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1219,
      format: "epc",
      idHex: "44415348504f525430373436",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:09.428-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1220,
      format: "epc",
      idHex: "44415348504f525430393137",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:09.887-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1221,
      format: "epc",
      idHex: "44415348504f525430373534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:10.115-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1222,
      format: "epc",
      idHex: "44415348504f525431303333",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:10.465-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1223,
      format: "epc",
      idHex: "44415348504f525430343538",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:10.582-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1224,
      format: "epc",
      idHex: "44415348504f525430353634",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:11.239-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1225,
      format: "epc",
      idHex: "44415348504f525431313437",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:11.925-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1226,
      format: "epc",
      idHex: "44415348504f525430353635",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:11.971-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1227,
      format: "epc",
      idHex: "44415348504f525430383530",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:12.001-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1228,
      format: "epc",
      idHex: "44415348504f525430373938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:12.215-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1229,
      format: "epc",
      idHex: "44415348504f525430313232",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:12.460-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 123,
      format: "epc",
      idHex: "44415348504f525430383733",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:24.476-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1230,
      format: "epc",
      idHex: "44415348504f525430313535",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:12.508-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1231,
      format: "epc",
      idHex: "44415348504f525430353636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:13.170-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1232,
      format: "epc",
      idHex: "44415348504f525430303138",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:13.198-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1233,
      format: "epc",
      idHex: "44415348504f525430353932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:13.672-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1234,
      format: "epc",
      idHex: "44415348504f525430343932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:14.391-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1235,
      format: "epc",
      idHex: "44415348504f525430343530",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:14.915-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1236,
      format: "epc",
      idHex: "44415348504f525430303238",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:15.193-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1237,
      format: "epc",
      idHex: "44415348504f525430323536",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:15.428-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1238,
      format: "epc",
      idHex: "44415348504f525430343534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:15.863-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1239,
      format: "epc",
      idHex: "44415348504f525430303733",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:16.309-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 124,
      format: "epc",
      idHex: "44415348504f525430323631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:24.744-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1240,
      format: "epc",
      idHex: "44415348504f525430343533",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:16.361-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1241,
      format: "epc",
      idHex: "44415348504f525430313037",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:17.069-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1242,
      format: "epc",
      idHex: "44415348504f525430383334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:17.255-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1243,
      format: "epc",
      idHex: "44415348504f525430303731",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:17.483-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1244,
      format: "epc",
      idHex: "44415348504f525430323336",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:17.847-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1245,
      format: "epc",
      idHex: "44415348504f525430323531",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:18.054-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1246,
      format: "epc",
      idHex: "44415348504f525430303732",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:19.163-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1247,
      format: "epc",
      idHex: "44415348504f525430323337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:19.175-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1248,
      format: "epc",
      idHex: "44415348504f525430303538",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:19.485-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1249,
      format: "epc",
      idHex: "44415348504f525430323331",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:20.612-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 125,
      format: "epc",
      idHex: "44415348504f525431303034",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:24.830-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1250,
      format: "epc",
      idHex: "44415348504f525430313230",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:20.635-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1251,
      format: "epc",
      idHex: "44415348504f525430323330",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:20.789-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1252,
      format: "epc",
      idHex: "44415348504f525430333039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:21.597-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1253,
      format: "epc",
      idHex: "44415348504f525431313630",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:22.649-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1254,
      format: "epc",
      idHex: "44415348504f525431313634",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:24.442-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1255,
      format: "epc",
      idHex: "44415348504f525430343532",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:56.453-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 1256,
      format: "epc",
      idHex: "44415348504f525430343536",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:44:56.465-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 126,
      format: "epc",
      idHex: "44415348504f525430333137",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:24.937-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 127,
      format: "epc",
      idHex: "44415348504f525430353035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:25.094-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 128,
      format: "epc",
      idHex: "44415348504f525430343436",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:25.173-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 129,
      format: "epc",
      idHex: "44415348504f525430373530",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:25.232-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 130,
      format: "epc",
      idHex: "44415348504f525430323335",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:25.729-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 131,
      format: "epc",
      idHex: "44415348504f525430393837",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:26.465-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 132,
      format: "epc",
      idHex: "44415348504f525430313731",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:26.507-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 133,
      format: "epc",
      idHex: "44415348504f525430343539",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:26.511-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 134,
      format: "epc",
      idHex: "44415348504f525430353739",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:26.578-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 135,
      format: "epc",
      idHex: "44415348504f525431313331",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:26.627-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 136,
      format: "epc",
      idHex: "44415348504f525430323632",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:26.878-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 137,
      format: "epc",
      idHex: "44415348504f525430353733",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:27.063-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 138,
      format: "epc",
      idHex: "44415348504f525430373433",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:27.111-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 139,
      format: "epc",
      idHex: "44415348504f525430323236",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:27.171-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 140,
      format: "epc",
      idHex: "44415348504f525430343935",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:27.179-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 141,
      format: "epc",
      idHex: "44415348504f525430383738",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:27.605-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 142,
      format: "epc",
      idHex: "44415348504f525430323934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:27.832-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 143,
      format: "epc",
      idHex: "44415348504f525430353532",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:27.845-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 144,
      format: "epc",
      idHex: "44415348504f525430303932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:27.917-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 145,
      format: "epc",
      idHex: "44415348504f525431313031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:28.040-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 146,
      format: "epc",
      idHex: "44415348504f525430343231",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:28.310-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 147,
      format: "epc",
      idHex: "44415348504f525430333735",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:28.354-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 148,
      format: "epc",
      idHex: "44415348504f525430323838",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:28.359-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 149,
      format: "epc",
      idHex: "44415348504f525430303931",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:28.372-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 150,
      format: "epc",
      idHex: "44415348504f525430313332",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:28.626-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 151,
      format: "epc",
      idHex: "44415348504f525430353237",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:28.634-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 152,
      format: "epc",
      idHex: "44415348504f525430363833",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:29.358-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 153,
      format: "epc",
      idHex: "44415348504f525430383837",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:29.372-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 154,
      format: "epc",
      idHex: "44415348504f525430353130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:29.409-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 155,
      format: "epc",
      idHex: "44415348504f525430363033",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:29.732-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 156,
      format: "epc",
      idHex: "44415348504f525430323131",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:29.736-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 157,
      format: "epc",
      idHex: "44415348504f525430313439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:29.754-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 158,
      format: "epc",
      idHex: "44415348504f525430353132",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:29.780-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 159,
      format: "epc",
      idHex: "44415348504f525430393839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:29.872-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 160,
      format: "epc",
      idHex: "44415348504f525430343339",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:29.893-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 161,
      format: "epc",
      idHex: "44415348504f525430363832",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:29.949-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 162,
      format: "epc",
      idHex: "44415348504f525430393836",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:30.150-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 163,
      format: "epc",
      idHex: "44415348504f525430303337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:30.187-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 164,
      format: "epc",
      idHex: "44415348504f525430303838",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:30.293-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 165,
      format: "epc",
      idHex: "44415348504f525431313034",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:30.789-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 166,
      format: "epc",
      idHex: "44415348504f525431303837",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:30.993-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 167,
      format: "epc",
      idHex: "44415348504f525430313135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:31.110-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 168,
      format: "epc",
      idHex: "44415348504f525431303032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:31.411-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 169,
      format: "epc",
      idHex: "44415348504f525430383532",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:31.603-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 170,
      format: "epc",
      idHex: "44415348504f525430333037",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:31.621-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 171,
      format: "epc",
      idHex: "44415348504f525430363036",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:31.771-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 172,
      format: "epc",
      idHex: "44415348504f525430303537",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:31.958-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 173,
      format: "epc",
      idHex: "44415348504f525431313330",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:32.420-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 174,
      format: "epc",
      idHex: "44415348504f525430353938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:32.453-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 175,
      format: "epc",
      idHex: "44415348504f525430343235",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:32.538-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 176,
      format: "epc",
      idHex: "44415348504f525430333535",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:32.606-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 177,
      format: "epc",
      idHex: "44415348504f525430363737",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:32.616-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 178,
      format: "epc",
      idHex: "44415348504f525430303939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:32.667-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 179,
      format: "epc",
      idHex: "44415348504f525430363232",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:32.761-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 180,
      format: "epc",
      idHex: "44415348504f525430363735",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:33.337-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 181,
      format: "epc",
      idHex: "44415348504f525430373130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:33.556-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 182,
      format: "epc",
      idHex: "44415348504f525430313735",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:33.574-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 183,
      format: "epc",
      idHex: "44415348504f525430303432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:33.599-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 184,
      format: "epc",
      idHex: "44415348504f525430363532",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:33.694-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 185,
      format: "epc",
      idHex: "44415348504f525430313830",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:33.732-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 186,
      format: "epc",
      idHex: "44415348504f525430333638",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:34.008-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 187,
      format: "epc",
      idHex: "44415348504f525430353537",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:34.029-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 188,
      format: "epc",
      idHex: "44415348504f525431303531",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:34.420-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 189,
      format: "epc",
      idHex: "44415348504f525430323532",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:34.627-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 190,
      format: "epc",
      idHex: "44415348504f525431323030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:34.690-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 191,
      format: "epc",
      idHex: "44415348504f525430383636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:35.094-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 192,
      format: "epc",
      idHex: "44415348504f525430333432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:35.139-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 193,
      format: "epc",
      idHex: "44415348504f525430303336",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:35.188-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 194,
      format: "epc",
      idHex: "44415348504f525430303134",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:35.429-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 195,
      format: "epc",
      idHex: "44415348504f525430343637",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:35.697-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 196,
      format: "epc",
      idHex: "44415348504f525430383736",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:35.799-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 197,
      format: "epc",
      idHex: "44415348504f525430303335",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:36.362-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 198,
      format: "epc",
      idHex: "44415348504f525430343234",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:36.579-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 199,
      format: "epc",
      idHex: "44415348504f525430343332",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:36.624-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 200,
      format: "epc",
      idHex: "44415348504f525430333536",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:36.806-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 201,
      format: "epc",
      idHex: "44415348504f525430353037",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:37.533-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 202,
      format: "epc",
      idHex: "44415348504f525430363939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:37.757-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 203,
      format: "epc",
      idHex: "44415348504f525430333239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:37.782-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 204,
      format: "epc",
      idHex: "44415348504f525430333330",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:37.817-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 205,
      format: "epc",
      idHex: "44415348504f525430313936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:38.367-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 206,
      format: "epc",
      idHex: "44415348504f525430353732",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:38.408-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 207,
      format: "epc",
      idHex: "44415348504f525430333431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:38.495-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 208,
      format: "epc",
      idHex: "44415348504f525430323839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:38.918-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 209,
      format: "epc",
      idHex: "44415348504f525431303737",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:38.935-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 210,
      format: "epc",
      idHex: "44415348504f525431303036",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:38.966-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 211,
      format: "epc",
      idHex: "44415348504f525430373431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:39.072-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 212,
      format: "epc",
      idHex: "44415348504f525430353236",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:39.415-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 213,
      format: "epc",
      idHex: "44415348504f525431303831",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:39.457-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 214,
      format: "epc",
      idHex: "44415348504f525430383938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:39.640-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 215,
      format: "epc",
      idHex: "44415348504f525430343232",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:39.653-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 216,
      format: "epc",
      idHex: "44415348504f525430363039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:40.157-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 217,
      format: "epc",
      idHex: "44415348504f525430333633",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:40.198-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 218,
      format: "epc",
      idHex: "44415348504f525430363130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:40.206-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 219,
      format: "epc",
      idHex: "44415348504f525430313235",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:40.210-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 220,
      format: "epc",
      idHex: "44415348504f525431313730",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:40.467-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 221,
      format: "epc",
      idHex: "44415348504f525430363732",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:40.507-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 222,
      format: "epc",
      idHex: "44415348504f525430353335",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:40.568-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 223,
      format: "epc",
      idHex: "44415348504f525430373733",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:40.770-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 224,
      format: "epc",
      idHex: "44415348504f525431313533",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:40.786-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 225,
      format: "epc",
      idHex: "44415348504f525431303136",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:40.803-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 226,
      format: "epc",
      idHex: "44415348504f525430303730",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:40.972-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 227,
      format: "epc",
      idHex: "44415348504f525430383639",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:41.198-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 228,
      format: "epc",
      idHex: "44415348504f525430373439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:41.308-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 229,
      format: "epc",
      idHex: "44415348504f525431313639",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:41.374-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 230,
      format: "epc",
      idHex: "44415348504f525431313438",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:41.537-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 231,
      format: "epc",
      idHex: "44415348504f525430353437",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:41.810-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 232,
      format: "epc",
      idHex: "44415348504f525430373532",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:42.177-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 233,
      format: "epc",
      idHex: "44415348504f525430333532",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:42.205-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 234,
      format: "epc",
      idHex: "44415348504f525430343832",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:42.852-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 235,
      format: "epc",
      idHex: "44415348504f525431303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:43.324-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 236,
      format: "epc",
      idHex: "44415348504f525430333930",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:43.528-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 237,
      format: "epc",
      idHex: "44415348504f525430333730",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:43.582-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 238,
      format: "epc",
      idHex: "44415348504f525431303135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:43.784-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 239,
      format: "epc",
      idHex: "44415348504f525430333433",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:44.344-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 240,
      format: "epc",
      idHex: "44415348504f525430373837",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:44.388-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 241,
      format: "epc",
      idHex: "44415348504f525430333438",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:44.454-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 242,
      format: "epc",
      idHex: "44415348504f525430303137",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:44.492-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 243,
      format: "epc",
      idHex: "44415348504f525430353133",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:44.714-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 244,
      format: "epc",
      idHex: "44415348504f525430323130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:49.634-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 245,
      format: "epc",
      idHex: "44415348504f525430313233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:50.789-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 246,
      format: "epc",
      idHex: "44415348504f525430333936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:51.081-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 247,
      format: "epc",
      idHex: "44415348504f525430393136",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:51.676-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 248,
      format: "epc",
      idHex: "44415348504f525430363437",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:53.023-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 249,
      format: "epc",
      idHex: "44415348504f525430353538",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:53.041-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 250,
      format: "epc",
      idHex: "44415348504f525431303939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:53.373-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 251,
      format: "epc",
      idHex: "44415348504f525430353337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:53.571-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 252,
      format: "epc",
      idHex: "44415348504f525430393034",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:02.162-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 253,
      format: "epc",
      idHex: "44415348504f525430323130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:09.916-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 254,
      format: "epc",
      idHex: "44415348504f525430313233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:10.806-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 255,
      format: "epc",
      idHex: "44415348504f525430333936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:11.201-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 256,
      format: "epc",
      idHex: "44415348504f525430393136",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:11.693-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 257,
      format: "epc",
      idHex: "44415348504f525430393538",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:12.386-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 258,
      format: "epc",
      idHex: "44415348504f525431303939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:13.509-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 259,
      format: "epc",
      idHex: "44415348504f525430353337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:13.627-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 260,
      format: "epc",
      idHex: "44415348504f525431303838",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:15.004-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 261,
      format: "epc",
      idHex: "44415348504f525430343930",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:25.977-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 262,
      format: "epc",
      idHex: "44415348504f525430323130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:30.845-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 263,
      format: "epc",
      idHex: "44415348504f525430333936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:31.293-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 264,
      format: "epc",
      idHex: "44415348504f525430393136",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:31.801-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 265,
      format: "epc",
      idHex: "44415348504f525430313233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:32.030-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 266,
      format: "epc",
      idHex: "44415348504f525431303939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:33.526-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 267,
      format: "epc",
      idHex: "44415348504f525430353337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:33.681-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 268,
      format: "epc",
      idHex: "44415348504f525430393538",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:35.009-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 269,
      format: "epc",
      idHex: "44415348504f525430323130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:50.867-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 27,
      format: "epc",
      idHex: "44415348504f525430383932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:04.763-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 270,
      format: "epc",
      idHex: "44415348504f525430393136",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:51.917-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 271,
      format: "epc",
      idHex: "44415348504f525430313233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:52.082-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 272,
      format: "epc",
      idHex: "44415348504f525431303939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:53.585-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 273,
      format: "epc",
      idHex: "44415348504f525430353337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:53.696-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 274,
      format: "epc",
      idHex: "44415348504f525430333936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:31:56.912-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 275,
      format: "epc",
      idHex: "44415348504f525430393538",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:00.124-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 276,
      format: "epc",
      idHex: "44415348504f525430393136",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:11.993-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 277,
      format: "epc",
      idHex: "44415348504f525430313233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:12.084-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 278,
      format: "epc",
      idHex: "44415348504f525430323130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:13.443-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 279,
      format: "epc",
      idHex: "44415348504f525431303939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:13.616-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 28,
      format: "epc",
      idHex: "44415348504f525430363636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:04.779-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 280,
      format: "epc",
      idHex: "44415348504f525430353337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:13.797-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 281,
      format: "epc",
      idHex: "44415348504f525430333939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:16.357-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 282,
      format: "epc",
      idHex: "44415348504f525430333936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:16.914-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 283,
      format: "epc",
      idHex: "44415348504f525431303838",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:17.538-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 284,
      format: "epc",
      idHex: "44415348504f525430343930",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:17.654-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 285,
      format: "epc",
      idHex: "44415348504f525430333537",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:17.672-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 286,
      format: "epc",
      idHex: "44415348504f525430393034",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:17.789-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 287,
      format: "epc",
      idHex: "44415348504f525430363132",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:18.122-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 288,
      format: "epc",
      idHex: "44415348504f525430393936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:19.146-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 289,
      format: "epc",
      idHex: "44415348504f525430383038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:19.159-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 29,
      format: "epc",
      idHex: "44415348504f525431313030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:05.949-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 290,
      format: "epc",
      idHex: "44415348504f525431313532",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:19.182-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 291,
      format: "epc",
      idHex: "44415348504f525431303330",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:19.215-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 292,
      format: "epc",
      idHex: "44415348504f525430363330",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:19.258-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 293,
      format: "epc",
      idHex: "44415348504f525430303333",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:19.533-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 294,
      format: "epc",
      idHex: "44415348504f525430343636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:19.585-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 295,
      format: "epc",
      idHex: "44415348504f525430383834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:20.006-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 296,
      format: "epc",
      idHex: "44415348504f525431313137",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:20.083-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 297,
      format: "epc",
      idHex: "44415348504f525430363334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:20.138-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 298,
      format: "epc",
      idHex: "44415348504f525430363239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:20.355-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 299,
      format: "epc",
      idHex: "44415348504f525430343233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:20.523-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 30,
      format: "epc",
      idHex: "44415348504f525431313432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:06.209-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 300,
      format: "epc",
      idHex: "44415348504f525430393639",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:20.925-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 301,
      format: "epc",
      idHex: "44415348504f525430353439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:21.126-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 302,
      format: "epc",
      idHex: "44415348504f525431313232",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:21.175-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 303,
      format: "epc",
      idHex: "44415348504f525430323434",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:21.410-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 304,
      format: "epc",
      idHex: "44415348504f525430363437",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:21.455-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 305,
      format: "epc",
      idHex: "44415348504f525430333632",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:21.752-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 306,
      format: "epc",
      idHex: "44415348504f525430393131",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:21.794-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 307,
      format: "epc",
      idHex: "44415348504f525430313039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:22.496-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 308,
      format: "epc",
      idHex: "44415348504f525430313032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:22.811-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 309,
      format: "epc",
      idHex: "44415348504f525430303737",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:22.825-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 310,
      format: "epc",
      idHex: "44415348504f525430373934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:22.965-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 31,
      format: "epc",
      idHex: "44415348504f525430373637",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:06.570-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 311,
      format: "epc",
      idHex: "44415348504f525430333938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:23.150-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 312,
      format: "epc",
      idHex: "44415348504f525430313339",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:23.185-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 313,
      format: "epc",
      idHex: "44415348504f525430383232",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:23.207-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 314,
      format: "epc",
      idHex: "44415348504f525430313438",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:23.440-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 315,
      format: "epc",
      idHex: "44415348504f525430343834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:24.063-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 316,
      format: "epc",
      idHex: "44415348504f525430323137",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:24.067-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 317,
      format: "epc",
      idHex: "44415348504f525430333436",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:24.088-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 318,
      format: "epc",
      idHex: "44415348504f525430383635",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:24.123-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 319,
      format: "epc",
      idHex: "44415348504f525431313631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:24.164-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 32,
      format: "epc",
      idHex: "44415348504f525431313139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:06.649-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 320,
      format: "epc",
      idHex: "44415348504f525430323139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:24.416-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 321,
      format: "epc",
      idHex: "44415348504f525430363337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:24.472-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 322,
      format: "epc",
      idHex: "44415348504f525430383531",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:24.523-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 323,
      format: "epc",
      idHex: "44415348504f525430353438",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:24.923-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 324,
      format: "epc",
      idHex: "44415348504f525430323836",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:24.931-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 325,
      format: "epc",
      idHex: "44415348504f525430303834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:24.981-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 326,
      format: "epc",
      idHex: "44415348504f525430303637",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:25.200-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 327,
      format: "epc",
      idHex: "44415348504f525430353934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:25.294-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 328,
      format: "epc",
      idHex: "44415348504f525430313338",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:25.820-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 329,
      format: "epc",
      idHex: "44415348504f525430353336",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:25.850-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 330,
      format: "epc",
      idHex: "44415348504f525430393430",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:25.866-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 33,
      format: "epc",
      idHex: "44415348504f525430393130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:08.571-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 331,
      format: "epc",
      idHex: "44415348504f525430353034",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:26.025-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 332,
      format: "epc",
      idHex: "44415348504f525431303532",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:26.046-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 333,
      format: "epc",
      idHex: "44415348504f525431313237",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:26.239-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 334,
      format: "epc",
      idHex: "44415348504f525430313238",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:26.359-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 335,
      format: "epc",
      idHex: "44415348504f525430353631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:26.406-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 336,
      format: "epc",
      idHex: "44415348504f525430353231",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:26.447-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 337,
      format: "epc",
      idHex: "44415348504f525431313734",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:26.502-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 338,
      format: "epc",
      idHex: "44415348504f525430343238",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:26.936-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 339,
      format: "epc",
      idHex: "44415348504f525430333833",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:27.199-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 34,
      format: "epc",
      idHex: "44415348504f525430373233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:24.399-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 35,
      format: "epc",
      idHex: "44415348504f525430363934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:24.438-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 36,
      format: "epc",
      idHex: "44415348504f525431313030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:26.189-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 37,
      format: "epc",
      idHex: "44415348504f525431313432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:26.330-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 38,
      format: "epc",
      idHex: "44415348504f525430363636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:33.954-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 39,
      format: "epc",
      idHex: "44415348504f525431313139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:38.028-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 40,
      format: "epc",
      idHex: "44415348504f525430383932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:40.125-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 41,
      format: "epc",
      idHex: "44415348504f525430373233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:44.412-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 42,
      format: "epc",
      idHex: "44415348504f525430363934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:44.875-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 43,
      format: "epc",
      idHex: "44415348504f525431313030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:46.262-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 44,
      format: "epc",
      idHex: "44415348504f525431313432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:46.607-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 45,
      format: "epc",
      idHex: "44415348504f525430363636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:28:54.045-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 46,
      format: "epc",
      idHex: "44415348504f525430383932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:00.156-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 47,
      format: "epc",
      idHex: "44415348504f525431313139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:00.245-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 48,
      format: "epc",
      idHex: "44415348504f525430363934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:05.298-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 49,
      format: "epc",
      idHex: "44415348504f525430373233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:05.385-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 50,
      format: "epc",
      idHex: "44415348504f525430393130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:05.694-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 51,
      format: "epc",
      idHex: "44415348504f525431313030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:06.496-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 52,
      format: "epc",
      idHex: "44415348504f525431303337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:14.130-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 53,
      format: "epc",
      idHex: "44415348504f525430383932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:20.298-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 54,
      format: "epc",
      idHex: "44415348504f525431313139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:20.384-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 55,
      format: "epc",
      idHex: "44415348504f525430363636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:22.411-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 56,
      format: "epc",
      idHex: "44415348504f525430373233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:25.467-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 57,
      format: "epc",
      idHex: "44415348504f525430363934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:25.748-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 58,
      format: "epc",
      idHex: "44415348504f525431313030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:26.906-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 59,
      format: "epc",
      idHex: "44415348504f525430383932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:40.363-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 60,
      format: "epc",
      idHex: "44415348504f525430363636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:42.494-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 340,
      format: "epc",
      idHex: "44415348504f525431303539",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:27.263-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 341,
      format: "epc",
      idHex: "44415348504f525430323634",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:27.279-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 342,
      format: "epc",
      idHex: "44415348504f525430343239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:27.707-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 343,
      format: "epc",
      idHex: "44415348504f525430363633",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:27.749-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 344,
      format: "epc",
      idHex: "44415348504f525431303633",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:28.121-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 345,
      format: "epc",
      idHex: "44415348504f525431303637",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:28.193-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 346,
      format: "epc",
      idHex: "44415348504f525430323138",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:28.291-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 347,
      format: "epc",
      idHex: "44415348504f525430383438",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:28.295-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 348,
      format: "epc",
      idHex: "44415348504f525430333533",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:28.337-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 349,
      format: "epc",
      idHex: "44415348504f525430313631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:28.395-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 350,
      format: "epc",
      idHex: "44415348504f525430313632",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:28.470-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 351,
      format: "epc",
      idHex: "44415348504f525430303836",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:28.775-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 352,
      format: "epc",
      idHex: "44415348504f525430363931",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:28.788-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 353,
      format: "epc",
      idHex: "44415348504f525430333634",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:29.382-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 354,
      format: "epc",
      idHex: "44415348504f525430363430",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:29.386-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 355,
      format: "epc",
      idHex: "44415348504f525431313436",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:29.953-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 356,
      format: "epc",
      idHex: "44415348504f525430333339",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:30.055-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 357,
      format: "epc",
      idHex: "44415348504f525430363538",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:30.579-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 358,
      format: "epc",
      idHex: "44415348504f525430343937",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:30.591-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 359,
      format: "epc",
      idHex: "44415348504f525431303535",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:30.609-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 360,
      format: "epc",
      idHex: "44415348504f525430323937",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:30.926-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 361,
      format: "epc",
      idHex: "44415348504f525430363237",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:30.942-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 362,
      format: "epc",
      idHex: "44415348504f525430363932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:31.041-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 363,
      format: "epc",
      idHex: "44415348504f525430363438",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:31.502-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 364,
      format: "epc",
      idHex: "44415348504f525430323933",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:31.539-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 365,
      format: "epc",
      idHex: "44415348504f525430363035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:31.671-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 366,
      format: "epc",
      idHex: "44415348504f525430323930",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:31.706-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 367,
      format: "epc",
      idHex: "44415348504f525430343330",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:31.901-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 368,
      format: "epc",
      idHex: "44415348504f525430303235",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:31.938-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 369,
      format: "epc",
      idHex: "44415348504f525430353833",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:32.228-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 370,
      format: "epc",
      idHex: "44415348504f525430323931",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:32.304-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 371,
      format: "epc",
      idHex: "44415348504f525430363432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:32.455-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 372,
      format: "epc",
      idHex: "44415348504f525430373731",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:32.546-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 373,
      format: "epc",
      idHex: "44415348504f525431303632",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:32.792-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 374,
      format: "epc",
      idHex: "44415348504f525430313236",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:32.804-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 375,
      format: "epc",
      idHex: "44415348504f525430323932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:33.320-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 376,
      format: "epc",
      idHex: "44415348504f525430303331",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:33.408-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 377,
      format: "epc",
      idHex: "44415348504f525430343434",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:33.437-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 378,
      format: "epc",
      idHex: "44415348504f525431313431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:33.441-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 379,
      format: "epc",
      idHex: "44415348504f525431313231",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:33.855-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 380,
      format: "epc",
      idHex: "44415348504f525430303133",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:33.885-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 381,
      format: "epc",
      idHex: "44415348504f525430393239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:34.031-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 382,
      format: "epc",
      idHex: "44415348504f525431303339",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:34.254-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 383,
      format: "epc",
      idHex: "44415348504f525430303131",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:34.332-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 384,
      format: "epc",
      idHex: "44415348504f525430393739",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:34.365-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 385,
      format: "epc",
      idHex: "44415348504f525430303531",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:34.472-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 386,
      format: "epc",
      idHex: "44415348504f525430313132",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:34.503-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 387,
      format: "epc",
      idHex: "44415348504f525431303933",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:34.917-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 388,
      format: "epc",
      idHex: "44415348504f525431313338",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:35.357-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 389,
      format: "epc",
      idHex: "44415348504f525430303636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:35.427-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 390,
      format: "epc",
      idHex: "44415348504f525430363335",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:35.431-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 391,
      format: "epc",
      idHex: "44415348504f525430353834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:35.506-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 392,
      format: "epc",
      idHex: "44415348504f525430323837",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:35.564-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 393,
      format: "epc",
      idHex: "44415348504f525431313233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:35.835-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 394,
      format: "epc",
      idHex: "44415348504f525430333737",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:36.451-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 395,
      format: "epc",
      idHex: "44415348504f525430303734",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:36.515-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 396,
      format: "epc",
      idHex: "44415348504f525431303937",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:36.904-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 397,
      format: "epc",
      idHex: "44415348504f525430303332",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:37.292-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 398,
      format: "epc",
      idHex: "44415348504f525431313036",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:37.328-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 399,
      format: "epc",
      idHex: "44415348504f525430393933",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:37.375-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 400,
      format: "epc",
      idHex: "44415348504f525430383933",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:37.391-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 401,
      format: "epc",
      idHex: "44415348504f525431313731",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:37.732-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 402,
      format: "epc",
      idHex: "44415348504f525431313636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:37.956-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 403,
      format: "epc",
      idHex: "44415348504f525430333935",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:38.117-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 404,
      format: "epc",
      idHex: "44415348504f525430373336",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:38.121-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 405,
      format: "epc",
      idHex: "44415348504f525430363730",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:38.374-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 406,
      format: "epc",
      idHex: "44415348504f525430333230",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:38.483-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 407,
      format: "epc",
      idHex: "44415348504f525430313730",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:38.515-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 408,
      format: "epc",
      idHex: "44415348504f525430383737",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:38.549-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 409,
      format: "epc",
      idHex: "44415348504f525430343531",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:38.681-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 410,
      format: "epc",
      idHex: "44415348504f525430343338",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:38.883-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 411,
      format: "epc",
      idHex: "44415348504f525430393531",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:38.899-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 412,
      format: "epc",
      idHex: "44415348504f525430363630",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:39.199-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 413,
      format: "epc",
      idHex: "44415348504f525430383833",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:39.203-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 414,
      format: "epc",
      idHex: "44415348504f525430323238",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:39.695-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 415,
      format: "epc",
      idHex: "44415348504f525430333337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:39.905-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 416,
      format: "epc",
      idHex: "44415348504f525430313038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:40.108-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 417,
      format: "epc",
      idHex: "44415348504f525430323533",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:40.138-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 418,
      format: "epc",
      idHex: "44415348504f525430333738",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:40.178-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 419,
      format: "epc",
      idHex: "44415348504f525430313633",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:40.752-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 420,
      format: "epc",
      idHex: "44415348504f525430353234",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:40.790-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 421,
      format: "epc",
      idHex: "44415348504f525430343433",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:40.823-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 422,
      format: "epc",
      idHex: "44415348504f525430313839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:40.899-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 423,
      format: "epc",
      idHex: "44415348504f525430353331",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:40.948-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 424,
      format: "epc",
      idHex: "44415348504f525430333332",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:41.480-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 425,
      format: "epc",
      idHex: "44415348504f525430353735",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:41.484-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 426,
      format: "epc",
      idHex: "44415348504f525430333637",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:41.503-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 427,
      format: "epc",
      idHex: "44415348504f525430343438",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:41.840-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 428,
      format: "epc",
      idHex: "44415348504f525430373137",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:41.920-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 429,
      format: "epc",
      idHex: "44415348504f525430313030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:42.029-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 430,
      format: "epc",
      idHex: "44415348504f525430343831",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:42.057-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 431,
      format: "epc",
      idHex: "44415348504f525430323638",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:42.581-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 432,
      format: "epc",
      idHex: "44415348504f525430373030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:42.618-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 433,
      format: "epc",
      idHex: "44415348504f525430303234",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:42.838-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 434,
      format: "epc",
      idHex: "44415348504f525431303734",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:42.913-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 435,
      format: "epc",
      idHex: "44415348504f525431303434",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:43.142-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 436,
      format: "epc",
      idHex: "44415348504f525430373932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:43.189-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 437,
      format: "epc",
      idHex: "44415348504f525430353837",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:43.193-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 438,
      format: "epc",
      idHex: "44415348504f525430333235",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:43.963-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 439,
      format: "epc",
      idHex: "44415348504f525431313430",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:44.063-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 440,
      format: "epc",
      idHex: "44415348504f525430303536",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:44.686-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 441,
      format: "epc",
      idHex: "44415348504f525430303439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:44.777-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 442,
      format: "epc",
      idHex: "44415348504f525430333038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:44.832-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 443,
      format: "epc",
      idHex: "44415348504f525430353531",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:45.827-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 444,
      format: "epc",
      idHex: "44415348504f525431313131",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:46.056-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 445,
      format: "epc",
      idHex: "44415348504f525430343333",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:46.374-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 446,
      format: "epc",
      idHex: "44415348504f525430323935",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:46.659-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 447,
      format: "epc",
      idHex: "44415348504f525430323539",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:47.199-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 448,
      format: "epc",
      idHex: "44415348504f525430303635",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:47.494-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 449,
      format: "epc",
      idHex: "44415348504f525430333430",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:47.498-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 450,
      format: "epc",
      idHex: "44415348504f525430343437",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:47.546-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 451,
      format: "epc",
      idHex: "44415348504f525430323939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:47.607-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 452,
      format: "epc",
      idHex: "44415348504f525430303438",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:47.635-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 453,
      format: "epc",
      idHex: "44415348504f525430333133",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:47.907-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 454,
      format: "epc",
      idHex: "44415348504f525430303535",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:48.241-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 455,
      format: "epc",
      idHex: "44415348504f525430323432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:48.271-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 456,
      format: "epc",
      idHex: "44415348504f525430353430",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:48.822-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 457,
      format: "epc",
      idHex: "44415348504f525430303436",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:48.839-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 458,
      format: "epc",
      idHex: "44415348504f525430333331",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:49.218-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 459,
      format: "epc",
      idHex: "44415348504f525430303739",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:49.235-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 460,
      format: "epc",
      idHex: "44415348504f525431303131",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:49.754-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 461,
      format: "epc",
      idHex: "44415348504f525430373334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:49.965-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 462,
      format: "epc",
      idHex: "44415348504f525430303437",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:50.176-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 463,
      format: "epc",
      idHex: "44415348504f525430343236",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:50.645-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 464,
      format: "epc",
      idHex: "44415348504f525430363731",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:32:50.674-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 465,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:18.833-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 466,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:20.985-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 467,
      format: "epc",
      idHex: "44415348504f525430373631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:21.902-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 468,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:22.357-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 469,
      format: "epc",
      idHex: "44415348504f525430323239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:22.361-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 470,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:23.299-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 471,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:23.553-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 472,
      format: "epc",
      idHex: "44415348504f525430393039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:27.013-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 473,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:38.918-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 474,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:41.952-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 475,
      format: "epc",
      idHex: "44415348504f525430323239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:42.523-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 476,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:43.479-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 477,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:43.621-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 478,
      format: "epc",
      idHex: "44415348504f525430373631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:46.718-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 479,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:49.709-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 480,
      format: "epc",
      idHex: "44415348504f525430393039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:33:53.326-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 481,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:02.275-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 482,
      format: "epc",
      idHex: "44415348504f525430323239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:02.584-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 483,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:03.505-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 484,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:03.654-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 485,
      format: "epc",
      idHex: "44415348504f525430373631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:06.731-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 486,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:12.434-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 487,
      format: "epc",
      idHex: "44415348504f525430393039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:13.881-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 488,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:14.304-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 489,
      format: "epc",
      idHex: "44415348504f525430323239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:22.636-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 490,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:22.849-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 491,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:23.543-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 492,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:23.706-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 493,
      format: "epc",
      idHex: "44415348504f525430373631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:32.965-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 494,
      format: "epc",
      idHex: "44415348504f525430393039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:34.134-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 495,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:42.576-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 496,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:42.946-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 497,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:43.824-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 498,
      format: "epc",
      idHex: "44415348504f525430323239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:44.431-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 499,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:45.705-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 500,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:51.569-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 501,
      format: "epc",
      idHex: "44415348504f525430373631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:53.615-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 502,
      format: "epc",
      idHex: "44415348504f525430393039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:34:54.205-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 503,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:02.630-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 504,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:02.973-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 505,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:03.939-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 506,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:06.337-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 507,
      format: "epc",
      idHex: "44415348504f525430323239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:06.391-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 508,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:12.377-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 509,
      format: "epc",
      idHex: "44415348504f525430373631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:13.895-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 510,
      format: "epc",
      idHex: "44415348504f525430393039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:14.347-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 511,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:22.679-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 512,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:24.169-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 513,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:24.190-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 514,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:26.422-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 515,
      format: "epc",
      idHex: "44415348504f525430323239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:30.553-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 516,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:35.887-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 517,
      format: "epc",
      idHex: "44415348504f525430373631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:36.234-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 518,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:42.757-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 519,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:44.250-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 520,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:45.341-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 521,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:46.508-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 522,
      format: "epc",
      idHex: "44415348504f525430323239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:50.635-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 523,
      format: "epc",
      idHex: "44415348504f525430393039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:51.734-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 524,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:35:56.020-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 525,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:02.819-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 526,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:04.326-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 527,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:06.144-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 528,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:06.564-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 529,
      format: "epc",
      idHex: "44415348504f525430323239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:12.917-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 530,
      format: "epc",
      idHex: "44415348504f525430393039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:15.188-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 531,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:16.073-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 532,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:22.945-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 533,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:24.330-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 534,
      format: "epc",
      idHex: "44415348504f525430373631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:25.661-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 535,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:26.198-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 536,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:27.118-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 537,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:36.199-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 538,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:42.976-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 539,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:45.060-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 540,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:46.303-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 541,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:47.524-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 542,
      format: "epc",
      idHex: "44415348504f525430373631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:49.593-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 543,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:36:56.332-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 544,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:05.152-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 545,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:06.353-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 546,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:07.745-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 547,
      format: "epc",
      idHex: "44415348504f525430393039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:09.397-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 548,
      format: "epc",
      idHex: "44415348504f525430373631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:09.673-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 549,
      format: "epc",
      idHex: "44415348504f525430323239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:12.745-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 550,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:16.347-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 551,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:17.098-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 552,
      format: "epc",
      idHex: "44415348504f525431313138",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:23.244-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 553,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:25.607-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 554,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:26.420-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 555,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:27.758-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 556,
      format: "epc",
      idHex: "44415348504f525430373631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:29.675-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 557,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:36.355-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 558,
      format: "epc",
      idHex: "44415348504f525430323239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:36.516-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 559,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:37.467-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 560,
      format: "epc",
      idHex: "44415348504f525431313138",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:43.371-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 561,
      format: "epc",
      idHex: "44415348504f525430393039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:43.942-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 562,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:45.745-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 563,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:46.521-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 564,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:47.832-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 565,
      format: "epc",
      idHex: "44415348504f525430373631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:49.772-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 566,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:56.391-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 567,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:37:57.605-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 568,
      format: "epc",
      idHex: "44415348504f525430323239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:01.903-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 569,
      format: "epc",
      idHex: "44415348504f525431313138",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:03.536-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 570,
      format: "epc",
      idHex: "44415348504f525430393039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:03.947-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 571,
      format: "epc",
      idHex: "44415348504f525430353135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:05.865-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 572,
      format: "epc",
      idHex: "44415348504f525430343334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:06.552-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 573,
      format: "epc",
      idHex: "44415348504f525430303534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:07.918-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 574,
      format: "epc",
      idHex: "44415348504f525430373631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:10.142-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 575,
      format: "epc",
      idHex: "44415348504f525431303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:17.723-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 576,
      format: "epc",
      idHex: "44415348504f525430383439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:17.789-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 577,
      format: "epc",
      idHex: "44415348504f525430383534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:20.852-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 578,
      format: "epc",
      idHex: "44415348504f525431303930",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:20.978-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 579,
      format: "epc",
      idHex: "44415348504f525430373634",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:21.035-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 580,
      format: "epc",
      idHex: "44415348504f525430383238",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:21.377-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 581,
      format: "epc",
      idHex: "44415348504f525430303339",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:21.386-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 582,
      format: "epc",
      idHex: "44415348504f525430373231",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:21.444-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 583,
      format: "epc",
      idHex: "44415348504f525430343335",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:21.459-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 584,
      format: "epc",
      idHex: "44415348504f525430353637",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:21.951-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 585,
      format: "epc",
      idHex: "44415348504f525430373031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:22.552-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 586,
      format: "epc",
      idHex: "44415348504f525430303632",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:22.564-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 587,
      format: "epc",
      idHex: "44415348504f525430393937",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:22.568-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 588,
      format: "epc",
      idHex: "44415348504f525430363531",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:22.607-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 589,
      format: "epc",
      idHex: "44415348504f525430303434",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:22.684-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 590,
      format: "epc",
      idHex: "44415348504f525430343431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:22.688-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 591,
      format: "epc",
      idHex: "44415348504f525431303836",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:22.741-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 592,
      format: "epc",
      idHex: "44415348504f525430343034",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:22.885-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 593,
      format: "epc",
      idHex: "44415348504f525430343337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:23.215-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 594,
      format: "epc",
      idHex: "44415348504f525431303934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:23.232-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 595,
      format: "epc",
      idHex: "44415348504f525430363037",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:23.629-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 596,
      format: "epc",
      idHex: "44415348504f525430343032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:23.656-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 597,
      format: "epc",
      idHex: "44415348504f525430383930",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:23.715-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 598,
      format: "epc",
      idHex: "44415348504f525430313430",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:23.776-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 599,
      format: "epc",
      idHex: "44415348504f525430393235",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:23.887-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 600,
      format: "epc",
      idHex: "44415348504f525430373338",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:24.119-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 601,
      format: "epc",
      idHex: "44415348504f525431313038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:24.164-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 602,
      format: "epc",
      idHex: "44415348504f525430363137",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:24.244-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 603,
      format: "epc",
      idHex: "44415348504f525430363734",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:24.344-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 604,
      format: "epc",
      idHex: "44415348504f525430303833",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:24.439-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 605,
      format: "epc",
      idHex: "44415348504f525430333739",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:24.699-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 606,
      format: "epc",
      idHex: "44415348504f525430363336",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:24.831-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 607,
      format: "epc",
      idHex: "44415348504f525430373339",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:24.921-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 608,
      format: "epc",
      idHex: "44415348504f525430363534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:25.006-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 609,
      format: "epc",
      idHex: "44415348504f525430383638",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:25.012-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 610,
      format: "epc",
      idHex: "44415348504f525430353036",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:25.122-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 61,
      format: "epc",
      idHex: "44415348504f525431313139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:42.632-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 611,
      format: "epc",
      idHex: "44415348504f525430383739",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:25.233-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 612,
      format: "epc",
      idHex: "44415348504f525431313230",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:25.638-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 613,
      format: "epc",
      idHex: "44415348504f525430303334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:25.657-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 614,
      format: "epc",
      idHex: "44415348504f525430323039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:25.715-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 615,
      format: "epc",
      idHex: "44415348504f525430333132",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:25.872-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 616,
      format: "epc",
      idHex: "44415348504f525430383131",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:25.916-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 617,
      format: "epc",
      idHex: "44415348504f525430353431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:26.204-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 618,
      format: "epc",
      idHex: "44415348504f525430353632",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:26.414-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 619,
      format: "epc",
      idHex: "44415348504f525430303630",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:26.612-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 62,
      format: "epc",
      idHex: "44415348504f525430363934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:45.787-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 620,
      format: "epc",
      idHex: "44415348504f525430343134",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:26.640-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 621,
      format: "epc",
      idHex: "44415348504f525430323233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:26.644-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 622,
      format: "epc",
      idHex: "44415348504f525431313433",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:26.875-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 623,
      format: "epc",
      idHex: "44415348504f525430383831",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:27.016-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 624,
      format: "epc",
      idHex: "44415348504f525430383135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:27.379-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 625,
      format: "epc",
      idHex: "44415348504f525430353935",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:27.412-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 626,
      format: "epc",
      idHex: "44415348504f525430303837",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:27.838-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 627,
      format: "epc",
      idHex: "44415348504f525430343733",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:27.954-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 628,
      format: "epc",
      idHex: "44415348504f525430303831",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:28.077-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 629,
      format: "epc",
      idHex: "44415348504f525430343631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:28.187-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 630,
      format: "epc",
      idHex: "44415348504f525430373335",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:28.226-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 631,
      format: "epc",
      idHex: "44415348504f525430383339",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:28.314-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 63,
      format: "epc",
      idHex: "44415348504f525430373233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:47.749-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 632,
      format: "epc",
      idHex: "44415348504f525430333933",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:28.359-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 633,
      format: "epc",
      idHex: "44415348504f525430363133",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:28.433-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 634,
      format: "epc",
      idHex: "44415348504f525430303832",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:29.067-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 635,
      format: "epc",
      idHex: "44415348504f525431313732",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:29.088-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 636,
      format: "epc",
      idHex: "44415348504f525430363739",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:29.109-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 637,
      format: "epc",
      idHex: "44415348504f525430393638",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:29.126-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 638,
      format: "epc",
      idHex: "44415348504f525430353533",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:29.521-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 639,
      format: "epc",
      idHex: "44415348504f525430323736",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:29.911-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 64,
      format: "epc",
      idHex: "44415348504f525431313030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:29:48.351-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 640,
      format: "epc",
      idHex: "44415348504f525430393934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:30.137-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 641,
      format: "epc",
      idHex: "44415348504f525430363031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:30.202-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 642,
      format: "epc",
      idHex: "44415348504f525430363435",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:30.260-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 643,
      format: "epc",
      idHex: "44415348504f525430363433",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:30.408-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 644,
      format: "epc",
      idHex: "44415348504f525430353038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:30.441-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 645,
      format: "epc",
      idHex: "44415348504f525431303335",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:30.679-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 646,
      format: "epc",
      idHex: "44415348504f525431313234",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:31.079-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 647,
      format: "epc",
      idHex: "44415348504f525430333636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:31.124-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 648,
      format: "epc",
      idHex: "44415348504f525430373039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:31.175-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 649,
      format: "epc",
      idHex: "44415348504f525430353238",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:31.378-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 65,
      format: "epc",
      idHex: "44415348504f525430383932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:00.743-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 650,
      format: "epc",
      idHex: "44415348504f525431313136",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:31.655-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 651,
      format: "epc",
      idHex: "44415348504f525430393132",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:32.036-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 652,
      format: "epc",
      idHex: "44415348504f525430343139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:32.095-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 653,
      format: "epc",
      idHex: "44415348504f525430303835",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:32.622-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 654,
      format: "epc",
      idHex: "44415348504f525430323735",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:32.661-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 655,
      format: "epc",
      idHex: "44415348504f525430373533",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:33.355-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 656,
      format: "epc",
      idHex: "44415348504f525430303839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:33.371-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 657,
      format: "epc",
      idHex: "44415348504f525430383937",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:33.460-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 658,
      format: "epc",
      idHex: "44415348504f525430343537",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:33.647-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 659,
      format: "epc",
      idHex: "44415348504f525430373332",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:33.829-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 66,
      format: "epc",
      idHex: "44415348504f525430363636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:03.041-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 660,
      format: "epc",
      idHex: "44415348504f525430383633",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:34.174-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 661,
      format: "epc",
      idHex: "44415348504f525430353139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:34.264-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 662,
      format: "epc",
      idHex: "44415348504f525430353638",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:34.299-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 663,
      format: "epc",
      idHex: "44415348504f525430323037",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:34.548-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 664,
      format: "epc",
      idHex: "44415348504f525430373437",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:34.551-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 665,
      format: "epc",
      idHex: "44415348504f525430323036",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:34.605-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 666,
      format: "epc",
      idHex: "44415348504f525430393330",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:34.618-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 667,
      format: "epc",
      idHex: "44415348504f525430323537",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:34.675-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 668,
      format: "epc",
      idHex: "44415348504f525430343035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:34.902-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 669,
      format: "epc",
      idHex: "44415348504f525430333838",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:35.197-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 67,
      format: "epc",
      idHex: "44415348504f525430393130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:03.755-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 670,
      format: "epc",
      idHex: "44415348504f525430373936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:35.525-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 671,
      format: "epc",
      idHex: "44415348504f525430353936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:35.608-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 672,
      format: "epc",
      idHex: "44415348504f525430343635",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:35.622-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 673,
      format: "epc",
      idHex: "44415348504f525430343038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:35.923-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 674,
      format: "epc",
      idHex: "44415348504f525430363936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:36.037-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 675,
      format: "epc",
      idHex: "44415348504f525430363635",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:36.055-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 676,
      format: "epc",
      idHex: "44415348504f525430333439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:36.347-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 677,
      format: "epc",
      idHex: "44415348504f525430343639",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:36.351-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 678,
      format: "epc",
      idHex: "44415348504f525430363638",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:36.587-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 679,
      format: "epc",
      idHex: "44415348504f525430343839",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:36.618-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 680,
      format: "epc",
      idHex: "44415348504f525430343638",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:36.711-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 68,
      format: "epc",
      idHex: "44415348504f525431313139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:03.858-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 681,
      format: "epc",
      idHex: "44415348504f525430363637",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:36.831-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 682,
      format: "epc",
      idHex: "44415348504f525430343039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:37.178-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 683,
      format: "epc",
      idHex: "44415348504f525430313832",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:37.322-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 684,
      format: "epc",
      idHex: "44415348504f525430393637",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:37.374-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 685,
      format: "epc",
      idHex: "44415348504f525431313134",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:37.378-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 686,
      format: "epc",
      idHex: "44415348504f525430383934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:37.411-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 687,
      format: "epc",
      idHex: "44415348504f525431303634",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:37.679-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 688,
      format: "epc",
      idHex: "44415348504f525430313630",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:37.921-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 689,
      format: "epc",
      idHex: "44415348504f525431303932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:38.037-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 69,
      format: "epc",
      idHex: "44415348504f525430373233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:07.830-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 690,
      format: "epc",
      idHex: "44415348504f525430333437",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:38.053-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 691,
      format: "epc",
      idHex: "44415348504f525431303334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:38.310-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 692,
      format: "epc",
      idHex: "44415348504f525430363935",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:38.322-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 693,
      format: "epc",
      idHex: "44415348504f525430363634",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:38.550-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 694,
      format: "epc",
      idHex: "44415348504f525431313334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:38.594-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 695,
      format: "epc",
      idHex: "44415348504f525430303934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:38.767-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 696,
      format: "epc",
      idHex: "44415348504f525430343138",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:39.025-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 697,
      format: "epc",
      idHex: "44415348504f525431303835",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:39.062-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 698,
      format: "epc",
      idHex: "44415348504f525430393539",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:39.150-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 699,
      format: "epc",
      idHex: "44415348504f525430353039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:39.378-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 70,
      format: "epc",
      idHex: "44415348504f525431313030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:08.432-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 700,
      format: "epc",
      idHex: "44415348504f525430363136",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:39.398-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 701,
      format: "epc",
      idHex: "44415348504f525430323534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:39.614-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 702,
      format: "epc",
      idHex: "44415348504f525431313435",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:39.643-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 703,
      format: "epc",
      idHex: "44415348504f525430373730",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:40.088-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 704,
      format: "epc",
      idHex: "44415348504f525430303533",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:40.182-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 705,
      format: "epc",
      idHex: "44415348504f525430333238",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:40.217-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 706,
      format: "epc",
      idHex: "44415348504f525430383036",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:40.457-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 707,
      format: "epc",
      idHex: "44415348504f525430313539",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:40.695-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 708,
      format: "epc",
      idHex: "44415348504f525430373034",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:40.953-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 709,
      format: "epc",
      idHex: "44415348504f525430313036",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:41.182-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 71,
      format: "epc",
      idHex: "44415348504f525430363934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:10.533-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 710,
      format: "epc",
      idHex: "44415348504f525430313532",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:41.239-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 711,
      format: "epc",
      idHex: "44415348504f525430393230",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:41.676-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 712,
      format: "epc",
      idHex: "44415348504f525430373835",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:41.745-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 713,
      format: "epc",
      idHex: "44415348504f525430343931",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:41.773-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 714,
      format: "epc",
      idHex: "44415348504f525430313736",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:41.842-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 715,
      format: "epc",
      idHex: "44415348504f525430393630",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:41.888-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 716,
      format: "epc",
      idHex: "44415348504f525430363139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:42.507-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 717,
      format: "epc",
      idHex: "44415348504f525430393035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:42.575-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 718,
      format: "epc",
      idHex: "44415348504f525431313339",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:43.113-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 719,
      format: "epc",
      idHex: "44415348504f525430363238",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:43.127-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 72,
      format: "epc",
      idHex: "44415348504f525431313432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:14.974-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 720,
      format: "epc",
      idHex: "44415348504f525430393636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:43.468-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 721,
      format: "epc",
      idHex: "44415348504f525430393037",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:43.518-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 722,
      format: "epc",
      idHex: "44415348504f525430363038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:43.835-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 723,
      format: "epc",
      idHex: "44415348504f525430383330",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:43.941-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 724,
      format: "epc",
      idHex: "44415348504f525430303139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:43.982-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 725,
      format: "epc",
      idHex: "44415348504f525431313733",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:44.039-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 726,
      format: "epc",
      idHex: "44415348504f525430313931",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:44.053-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 727,
      format: "epc",
      idHex: "44415348504f525430353639",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:44.306-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 728,
      format: "epc",
      idHex: "44415348504f525431313135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:44.371-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 729,
      format: "epc",
      idHex: "44415348504f525430313139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:44.407-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 73,
      format: "epc",
      idHex: "44415348504f525430373637",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:15.310-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 730,
      format: "epc",
      idHex: "44415348504f525430353434",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:44.781-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 731,
      format: "epc",
      idHex: "44415348504f525430383139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:45.303-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 732,
      format: "epc",
      idHex: "44415348504f525430333931",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:45.331-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 733,
      format: "epc",
      idHex: "44415348504f525430373237",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:45.366-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 734,
      format: "epc",
      idHex: "44415348504f525430333836",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:45.424-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 735,
      format: "epc",
      idHex: "44415348504f525430313835",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:45.520-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 736,
      format: "epc",
      idHex: "44415348504f525430343033",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:45.623-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 737,
      format: "epc",
      idHex: "44415348504f525430313836",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:46.143-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 738,
      format: "epc",
      idHex: "44415348504f525430363231",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:46.159-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 739,
      format: "epc",
      idHex: "44415348504f525430373132",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:46.171-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 74,
      format: "epc",
      idHex: "44415348504f525431303337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:15.526-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 740,
      format: "epc",
      idHex: "44415348504f525430343430",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:46.983-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 741,
      format: "epc",
      idHex: "44415348504f525430393537",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:46.996-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 742,
      format: "epc",
      idHex: "44415348504f525431313638",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:47.228-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 743,
      format: "epc",
      idHex: "44415348504f525430373333",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:47.291-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 744,
      format: "epc",
      idHex: "44415348504f525430313637",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:47.350-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 745,
      format: "epc",
      idHex: "44415348504f525430373635",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:47.368-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 746,
      format: "epc",
      idHex: "44415348504f525430353436",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:47.756-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 747,
      format: "epc",
      idHex: "44415348504f525431303132",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:47.815-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 748,
      format: "epc",
      idHex: "44415348504f525430363030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:47.925-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 749,
      format: "epc",
      idHex: "44415348504f525430393238",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:48.168-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 75,
      format: "epc",
      idHex: "44415348504f525430363332",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:15.640-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 750,
      format: "epc",
      idHex: "44415348504f525430333832",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:48.408-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 751,
      format: "epc",
      idHex: "44415348504f525430313636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:48.428-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 752,
      format: "epc",
      idHex: "44415348504f525430303239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:48.732-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 753,
      format: "epc",
      idHex: "44415348504f525430353530",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:48.926-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 754,
      format: "epc",
      idHex: "44415348504f525430313739",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:48.955-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 755,
      format: "epc",
      idHex: "44415348504f525430303031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:49.454-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 756,
      format: "epc",
      idHex: "44415348504f525430353734",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:49.470-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 757,
      format: "epc",
      idHex: "44415348504f525430373032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:49.515-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 758,
      format: "epc",
      idHex: "44415348504f525430393331",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:49.740-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 759,
      format: "epc",
      idHex: "44415348504f525431313039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:49.861-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 76,
      format: "epc",
      idHex: "44415348504f525430343135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:15.653-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 760,
      format: "epc",
      idHex: "44415348504f525430333539",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:49.915-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 761,
      format: "epc",
      idHex: "44415348504f525430373636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:50.384-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 762,
      format: "epc",
      idHex: "44415348504f525430373935",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:50.403-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 763,
      format: "epc",
      idHex: "44415348504f525430373434",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:50.482-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 764,
      format: "epc",
      idHex: "44415348504f525430333131",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:50.585-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 765,
      format: "epc",
      idHex: "44415348504f525430393939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:50.623-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 766,
      format: "epc",
      idHex: "44415348504f525430393737",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:50.649-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 767,
      format: "epc",
      idHex: "44415348504f525430333233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:50.792-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 768,
      format: "epc",
      idHex: "44415348504f525430303034",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:51.212-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 769,
      format: "epc",
      idHex: "44415348504f525431303133",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:51.230-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 77,
      format: "epc",
      idHex: "44415348504f525430343833",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:16.101-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 770,
      format: "epc",
      idHex: "44415348504f525430393932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:51.690-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 771,
      format: "epc",
      idHex: "44415348504f525430333335",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:51.695-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 772,
      format: "epc",
      idHex: "44415348504f525430363338",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:51.726-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 773,
      format: "epc",
      idHex: "44415348504f525430353137",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:51.759-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 774,
      format: "epc",
      idHex: "44415348504f525430313638",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:52.304-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 775,
      format: "epc",
      idHex: "44415348504f525430313938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:52.528-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 776,
      format: "epc",
      idHex: "44415348504f525430383337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:52.547-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 777,
      format: "epc",
      idHex: "44415348504f525430373236",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:52.654-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 778,
      format: "epc",
      idHex: "44415348504f525431303437",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:52.704-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 779,
      format: "epc",
      idHex: "44415348504f525430313937",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:52.960-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 78,
      format: "epc",
      idHex: "44415348504f525431303236",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:16.523-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 780,
      format: "epc",
      idHex: "44415348504f525430373931",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:53.272-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 781,
      format: "epc",
      idHex: "44415348504f525431313239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:53.307-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 782,
      format: "epc",
      idHex: "44415348504f525431303736",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:53.604-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 783,
      format: "epc",
      idHex: "44415348504f525430303631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:53.630-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 784,
      format: "epc",
      idHex: "44415348504f525431303038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:53.805-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 785,
      format: "epc",
      idHex: "44415348504f525430373539",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:53.898-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 786,
      format: "epc",
      idHex: "44415348504f525430373331",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:54.496-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 787,
      format: "epc",
      idHex: "44415348504f525430313331",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:54.509-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 788,
      format: "epc",
      idHex: "44415348504f525430383338",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:54.513-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 789,
      format: "epc",
      idHex: "44415348504f525430363535",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:54.912-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 79,
      format: "epc",
      idHex: "44415348504f525431303235",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:16.800-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 790,
      format: "epc",
      idHex: "44415348504f525430303039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:54.991-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 791,
      format: "epc",
      idHex: "44415348504f525430303937",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:55.062-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 792,
      format: "epc",
      idHex: "44415348504f525430333232",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:55.088-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 793,
      format: "epc",
      idHex: "44415348504f525431313531",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:55.283-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 794,
      format: "epc",
      idHex: "44415348504f525430313737",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:55.305-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 795,
      format: "epc",
      idHex: "44415348504f525430373739",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:55.899-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 796,
      format: "epc",
      idHex: "44415348504f525430313337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:55.914-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 797,
      format: "epc",
      idHex: "44415348504f525430393031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:56.042-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 798,
      format: "epc",
      idHex: "44415348504f525431303639",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:56.319-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 799,
      format: "epc",
      idHex: "44415348504f525430333538",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:56.326-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 80,
      format: "epc",
      idHex: "44415348504f525430343031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:17.071-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 81,
      format: "epc",
      idHex: "44415348504f525430303935",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:17.105-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 82,
      format: "epc",
      idHex: "44415348504f525430363631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:17.129-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 800,
      format: "epc",
      idHex: "44415348504f525430383838",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:56.538-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 801,
      format: "epc",
      idHex: "44415348504f525430333834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:56.550-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 802,
      format: "epc",
      idHex: "44415348504f525431303035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:56.569-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 803,
      format: "epc",
      idHex: "44415348504f525430343939",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:56.917-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 804,
      format: "epc",
      idHex: "44415348504f525430313034",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:56.962-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 805,
      format: "epc",
      idHex: "44415348504f525431313238",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:57.360-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 806,
      format: "epc",
      idHex: "44415348504f525431313335",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:57.640-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 807,
      format: "epc",
      idHex: "44415348504f525430393835",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:57.872-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 808,
      format: "epc",
      idHex: "44415348504f525430323633",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:57.967-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 809,
      format: "epc",
      idHex: "44415348504f525431313336",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:57.996-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 810,
      format: "epc",
      idHex: "44415348504f525430393634",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:58.218-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 811,
      format: "epc",
      idHex: "44415348504f525430383632",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:58.275-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 812,
      format: "epc",
      idHex: "44415348504f525430303233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:58.440-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 813,
      format: "epc",
      idHex: "44415348504f525430353339",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:58.477-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 814,
      format: "epc",
      idHex: "44415348504f525431303739",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:58.489-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 815,
      format: "epc",
      idHex: "44415348504f525430323235",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:58.779-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 816,
      format: "epc",
      idHex: "44415348504f525430313334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:59.279-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 817,
      format: "epc",
      idHex: "44415348504f525430373138",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:59.304-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 818,
      format: "epc",
      idHex: "44415348504f525430353031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:59.384-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 819,
      format: "epc",
      idHex: "44415348504f525431303530",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:59.538-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 820,
      format: "epc",
      idHex: "44415348504f525430303532",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:38:59.935-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 821,
      format: "epc",
      idHex: "44415348504f525430333333",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:00.014-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 822,
      format: "epc",
      idHex: "44415348504f525430383637",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:00.457-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 823,
      format: "epc",
      idHex: "44415348504f525431303237",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:00.924-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 824,
      format: "epc",
      idHex: "44415348504f525430363431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:00.932-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 825,
      format: "epc",
      idHex: "44415348504f525431303538",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:00.945-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 826,
      format: "epc",
      idHex: "44415348504f525431303730",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:01.580-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 827,
      format: "epc",
      idHex: "44415348504f525430393832",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:01.602-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 828,
      format: "epc",
      idHex: "44415348504f525430313534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:02.474-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 829,
      format: "epc",
      idHex: "44415348504f525430313533",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:02.706-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 83,
      format: "epc",
      idHex: "44415348504f525431303338",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:17.557-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 830,
      format: "epc",
      idHex: "44415348504f525431303239",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:03.412-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 831,
      format: "epc",
      idHex: "44415348504f525430343732",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:03.454-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 832,
      format: "epc",
      idHex: "44415348504f525430393631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:03.500-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 833,
      format: "epc",
      idHex: "44415348504f525430343633",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:03.847-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 834,
      format: "epc",
      idHex: "44415348504f525431313637",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:03.852-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 835,
      format: "epc",
      idHex: "44415348504f525430343132",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:03.897-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 836,
      format: "epc",
      idHex: "44415348504f525430333631",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:04.650-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 837,
      format: "epc",
      idHex: "44415348504f525430323339",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:05.278-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 838,
      format: "epc",
      idHex: "44415348504f525430393831",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:05.475-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 839,
      format: "epc",
      idHex: "44415348504f525430343837",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:05.792-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 840,
      format: "epc",
      idHex: "44415348504f525430363135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:05.805-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 84,
      format: "epc",
      idHex: "44415348504f525430393536",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:17.671-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 841,
      format: "epc",
      idHex: "44415348504f525430393730",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:05.904-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 842,
      format: "epc",
      idHex: "44415348504f525430313639",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:06.409-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 843,
      format: "epc",
      idHex: "44415348504f525430393735",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:06.824-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 844,
      format: "epc",
      idHex: "44415348504f525430333736",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:06.916-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 845,
      format: "epc",
      idHex: "44415348504f525430353334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:07.022-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 846,
      format: "epc",
      idHex: "44415348504f525430393738",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:07.416-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 847,
      format: "epc",
      idHex: "44415348504f525430393830",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:07.761-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 848,
      format: "epc",
      idHex: "44415348504f525430393838",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:08.174-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 849,
      format: "epc",
      idHex: "44415348504f525431313539",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:08.210-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 85,
      format: "epc",
      idHex: "44415348504f525430343830",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:17.688-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 850,
      format: "epc",
      idHex: "44415348504f525430323034",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:09.177-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 851,
      format: "epc",
      idHex: "44415348504f525430303634",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:09.199-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 852,
      format: "epc",
      idHex: "44415348504f525430333237",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:09.396-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 853,
      format: "epc",
      idHex: "44415348504f525430333236",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:09.401-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 854,
      format: "epc",
      idHex: "44415348504f525430333435",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:09.462-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 855,
      format: "epc",
      idHex: "44415348504f525431303232",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:09.523-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 856,
      format: "epc",
      idHex: "44415348504f525431313337",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:09.597-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 857,
      format: "epc",
      idHex: "44415348504f525431313633",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:09.929-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 858,
      format: "epc",
      idHex: "44415348504f525430313435",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:10.425-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 859,
      format: "epc",
      idHex: "44415348504f525430333534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:10.775-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 86,
      format: "epc",
      idHex: "44415348504f525430363539",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:18.312-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 860,
      format: "epc",
      idHex: "44415348504f525430323133",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:11.003-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 861,
      format: "epc",
      idHex: "44415348504f525430383436",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:11.133-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 862,
      format: "epc",
      idHex: "44415348504f525430343632",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:11.390-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 863,
      format: "epc",
      idHex: "44415348504f525430363236",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:12.084-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 864,
      format: "epc",
      idHex: "44415348504f525430343439",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:12.841-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 865,
      format: "epc",
      idHex: "44415348504f525430343435",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:12.849-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 866,
      format: "epc",
      idHex: "44415348504f525430303938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:12.882-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 867,
      format: "epc",
      idHex: "44415348504f525430333032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:13.288-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 868,
      format: "epc",
      idHex: "44415348504f525430303033",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:13.549-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 869,
      format: "epc",
      idHex: "44415348504f525430333130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:13.609-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 87,
      format: "epc",
      idHex: "44415348504f525430333630",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:18.352-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 88,
      format: "epc",
      idHex: "44415348504f525430373638",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:18.400-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 870,
      format: "epc",
      idHex: "44415348504f525430343630",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:13.656-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 871,
      format: "epc",
      idHex: "44415348504f525430363830",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:14.128-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 872,
      format: "epc",
      idHex: "44415348504f525430393938",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:14.193-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 873,
      format: "epc",
      idHex: "44415348504f525430323038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:14.518-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 874,
      format: "epc",
      idHex: "44415348504f525430373037",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:14.895-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 875,
      format: "epc",
      idHex: "44415348504f525430363831",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:14.973-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 876,
      format: "epc",
      idHex: "44415348504f525430343130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:15.034-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 877,
      format: "epc",
      idHex: "44415348504f525430323030",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:15.164-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 878,
      format: "epc",
      idHex: "44415348504f525430353138",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:15.904-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 879,
      format: "epc",
      idHex: "44415348504f525430373036",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:16.031-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 880,
      format: "epc",
      idHex: "44415348504f525430313934",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:16.244-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 881,
      format: "epc",
      idHex: "44415348504f525430303430",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:16.743-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 882,
      format: "epc",
      idHex: "44415348504f525430343835",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:16.949-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 883,
      format: "epc",
      idHex: "44415348504f525430383734",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:17.717-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 884,
      format: "epc",
      idHex: "44415348504f525430343131",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:17.730-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 885,
      format: "epc",
      idHex: "44415348504f525430373234",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:17.946-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 886,
      format: "epc",
      idHex: "44415348504f525430373630",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:18.173-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 887,
      format: "epc",
      idHex: "44415348504f525430373136",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:18.206-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 888,
      format: "epc",
      idHex: "44415348504f525430313436",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:18.963-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 889,
      format: "epc",
      idHex: "44415348504f525430383039",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:19.056-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 89,
      format: "epc",
      idHex: "44415348504f525431303438",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:18.837-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 90,
      format: "epc",
      idHex: "44415348504f525430393237",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:19.010-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 890,
      format: "epc",
      idHex: "44415348504f525430313138",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:19.326-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 891,
      format: "epc",
      idHex: "44415348504f525430313932",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:19.931-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 892,
      format: "epc",
      idHex: "44415348504f525430363234",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:19.964-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 893,
      format: "epc",
      idHex: "44415348504f525430383031",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:20.567-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 894,
      format: "epc",
      idHex: "44415348504f525430383137",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:21.774-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 895,
      format: "epc",
      idHex: "44415348504f525430363032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:22.832-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 896,
      format: "epc",
      idHex: "44415348504f525430323035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:57.241-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 897,
      format: "epc",
      idHex: "44415348504f525431313035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:39:59.952-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 898,
      format: "epc",
      idHex: "44415348504f525430353435",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:00.737-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 899,
      format: "epc",
      idHex: "44415348504f525430343836",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:00.880-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 900,
      format: "epc",
      idHex: "44415348504f525430313837",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:03.281-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 901,
      format: "epc",
      idHex: "44415348504f525430393336",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:03.305-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 902,
      format: "epc",
      idHex: "44415348504f525430323334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:04.565-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 903,
      format: "epc",
      idHex: "44415348504f525430393038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:05.181-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 904,
      format: "epc",
      idHex: "44415348504f525430323035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:17.247-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 905,
      format: "epc",
      idHex: "44415348504f525431313035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:20.044-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 906,
      format: "epc",
      idHex: "44415348504f525430393336",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:23.810-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 907,
      format: "epc",
      idHex: "44415348504f525430323334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:24.621-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 908,
      format: "epc",
      idHex: "44415348504f525430393038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:25.200-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 909,
      format: "epc",
      idHex: "44415348504f525430313837",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:33.326-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 91,
      format: "epc",
      idHex: "44415348504f525430313530",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:19.035-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 910,
      format: "epc",
      idHex: "44415348504f525430323035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:37.361-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 911,
      format: "epc",
      idHex: "44415348504f525431313035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:43.954-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 912,
      format: "epc",
      idHex: "44415348504f525430323334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:44.660-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 913,
      format: "epc",
      idHex: "44415348504f525430393038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:45.229-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 914,
      format: "epc",
      idHex: "44415348504f525430343836",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:45.928-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 915,
      format: "epc",
      idHex: "44415348504f525430393336",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:46.087-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 916,
      format: "epc",
      idHex: "44415348504f525430393437",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:49.115-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 917,
      format: "epc",
      idHex: "44415348504f525430313837",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:54.843-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 918,
      format: "epc",
      idHex: "44415348504f525430323035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:40:57.384-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 919,
      format: "epc",
      idHex: "44415348504f525431313035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:04.070-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 92,
      format: "epc",
      idHex: "44415348504f525430303539",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:19.402-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 920,
      format: "epc",
      idHex: "44415348504f525430393038",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:05.266-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 921,
      format: "epc",
      idHex: "44415348504f525430393336",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:06.119-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 922,
      format: "epc",
      idHex: "44415348504f525430343836",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:07.663-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 923,
      format: "epc",
      idHex: "44415348504f525430323334",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:08.404-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 924,
      format: "epc",
      idHex: "44415348504f525430313837",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:15.302-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 925,
      format: "epc",
      idHex: "44415348504f525431303936",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:16.037-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 926,
      format: "epc",
      idHex: "44415348504f525430393437",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:16.722-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 927,
      format: "epc",
      idHex: "44415348504f525431303735",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:16.806-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 928,
      format: "epc",
      idHex: "44415348504f525430353435",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:17.045-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 929,
      format: "epc",
      idHex: "44415348504f525430373033",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:17.476-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 930,
      format: "epc",
      idHex: "44415348504f525430373833",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:17.527-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 93,
      format: "epc",
      idHex: "44415348504f525430343737",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:19.892-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 931,
      format: "epc",
      idHex: "44415348504f525430333231",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:17.638-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 932,
      format: "epc",
      idHex: "44415348504f525430373139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:17.904-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 933,
      format: "epc",
      idHex: "44415348504f525430353832",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:18.958-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 934,
      format: "epc",
      idHex: "44415348504f525431303636",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:18.970-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 935,
      format: "epc",
      idHex: "44415348504f525430393533",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:19.123-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 936,
      format: "epc",
      idHex: "44415348504f525430353433",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:19.137-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 937,
      format: "epc",
      idHex: "44415348504f525430353933",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:19.476-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 938,
      format: "epc",
      idHex: "44415348504f525430303135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:19.523-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 939,
      format: "epc",
      idHex: "44415348504f525430323635",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:19.576-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 94,
      format: "epc",
      idHex: "44415348504f525430333338",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:19.909-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 95,
      format: "epc",
      idHex: "44415348504f525430303435",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:19.934-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 940,
      format: "epc",
      idHex: "44415348504f525430353736",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:19.595-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 941,
      format: "epc",
      idHex: "44415348504f525431313236",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:19.639-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 942,
      format: "epc",
      idHex: "44415348504f525430313935",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:19.658-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 943,
      format: "epc",
      idHex: "44415348504f525430363933",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:19.678-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 944,
      format: "epc",
      idHex: "44415348504f52543130b63e",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:19.765-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 945,
      format: "epc",
      idHex: "44415348504f525430323234",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:20.247-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 946,
      format: "epc",
      idHex: "44415348504f525431313032",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:20.480-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 947,
      format: "epc",
      idHex: "44415348504f525431313538",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:20.533-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 948,
      format: "epc",
      idHex: "44415348504f525431303537",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:20.541-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 949,
      format: "epc",
      idHex: "44415348504f525430353836",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:20.822-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 950,
      format: "epc",
      idHex: "44415348504f525430353831",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:20.827-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 951,
      format: "epc",
      idHex: "44415348504f525430343432",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:20.977-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 952,
      format: "epc",
      idHex: "44415348504f525430333336",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:20.994-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 953,
      format: "epc",
      idHex: "44415348504f525431303332",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:21.225-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 954,
      format: "epc",
      idHex: "44415348504f525430363138",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:21.277-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 955,
      format: "epc",
      idHex: "44415348504f525430373535",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:21.369-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 956,
      format: "epc",
      idHex: "44415348504f525430363230",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:21.615-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 957,
      format: "epc",
      idHex: "44415348504f525430313933",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:21.638-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 958,
      format: "epc",
      idHex: "44415348504f525430333139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:22.107-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 959,
      format: "epc",
      idHex: "44415348504f525430363235",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:22.147-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 96,
      format: "epc",
      idHex: "44415348504f525430373035",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:20.184-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 960,
      format: "epc",
      idHex: "44415348504f525430323737",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:22.417-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 961,
      format: "epc",
      idHex: "44415348504f525430393233",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:22.421-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 962,
      format: "epc",
      idHex: "44415348504f525430373930",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:22.826-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 963,
      format: "epc",
      idHex: "44415348504f525430383136",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:22.881-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 964,
      format: "epc",
      idHex: "44415348504f525430323835",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:23.190-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 965,
      format: "epc",
      idHex: "44415348504f525430313131",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:23.739-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 966,
      format: "epc",
      idHex: "44415348504f525430313237",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:23.757-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 967,
      format: "epc",
      idHex: "44415348504f525431303033",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:23.847-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 968,
      format: "epc",
      idHex: "44415348504f525430333731",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:24.290-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 969,
      format: "epc",
      idHex: "44415348504f525430363436",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:24.381-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 97,
      format: "epc",
      idHex: "44415348504f525431313536",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:20.541-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 970,
      format: "epc",
      idHex: "44415348504f525430383435",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:24.442-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 971,
      format: "epc",
      idHex: "44415348504f525430303830",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:24.650-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 972,
      format: "epc",
      idHex: "44415348504f525430303738",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:24.929-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 973,
      format: "epc",
      idHex: "44415348504f525430383730",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:24.985-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 974,
      format: "epc",
      idHex: "44415348504f525431313632",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:25.240-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 975,
      format: "epc",
      idHex: "44415348504f525430393135",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:25.253-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 976,
      format: "epc",
      idHex: "44415348504f525430333639",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:25.491-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 977,
      format: "epc",
      idHex: "44415348504f525430343535",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:25.548-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 978,
      format: "epc",
      idHex: "44415348504f525430383130",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:25.672-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 979,
      format: "epc",
      idHex: "44415348504f525430313635",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:26.258-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 98,
      format: "epc",
      idHex: "44415348504f525430303638",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:20.981-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 980,
      format: "epc",
      idHex: "44415348504f525430303431",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:26.477-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 981,
      format: "epc",
      idHex: "44415348504f525431303832",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:26.652-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 982,
      format: "epc",
      idHex: "44415348504f525430393139",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:26.714-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 983,
      format: "epc",
      idHex: "44415348504f525430353830",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:26.741-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 984,
      format: "epc",
      idHex: "44415348504f525430383433",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:26.761-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 985,
      format: "epc",
      idHex: "44415348504f525430363836",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:26.835-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 986,
      format: "epc",
      idHex: "44415348504f525430333733",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:26.933-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 987,
      format: "epc",
      idHex: "44415348504f525430393632",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:27.143-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 988,
      format: "epc",
      idHex: "44415348504f525430313732",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:27.521-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 989,
      format: "epc",
      idHex: "44415348504f525430353536",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:27.541-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 99,
      format: "epc",
      idHex: "44415348504f525431303331",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:30:21.001-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 990,
      format: "epc",
      idHex: "44415348504f525430383034",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:27.802-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 991,
      format: "epc",
      idHex: "44415348504f525430383033",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:28.235-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 992,
      format: "epc",
      idHex: "44415348504f525430373736",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:28.472-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 993,
      format: "epc",
      idHex: "44415348504f525430353931",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:28.738-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 994,
      format: "epc",
      idHex: "44415348504f525430383539",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:28.804-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 995,
      format: "epc",
      idHex: "44415348504f525431313534",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:28.875-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 996,
      format: "epc",
      idHex: "44415348504f525430363834",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:29.619-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 997,
      format: "epc",
      idHex: "44415348504f525430363738",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:30.230-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 998,
      format: "epc",
      idHex: "44415348504f525430373732",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:30.258-0600",
    type: "SIMPLE",
  },
  {
    data: {
      eventNum: 999,
      format: "epc",
      idHex: "44415348504f525430353630",
      userDefined: "874/1/true",
    },
    timestamp: "2025-02-23T06:41:30.584-0600",
    type: "SIMPLE",
  },
];
