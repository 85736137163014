import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";
import createEventConfig from "./EventCreate/createEventconfig.json";
import {
  updateEventAttribute,
  deleteSubEvent,
} from "../../../../data-store/actions/events-actions";
import EditableField from "../../../EditableField/EditableField";
import Field from "../../../Fields/Field";
import EventSpecificGender from "./EventSpecificGender";
import PricingStages from "./PricingStages/PricingStages";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "15px",
    margin: "15px",
    textAlign: "left",
    overflowY: "scroll",
    width: "100%",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function EventSpecificSubEvent(props) {
  const { subEventIndex, subEvent } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfigurationData = useSelector(
    (state) => state.registry.registryConfiguration
  );

  const [showDetails, setShowDetails] = useState(false);

  const createEventObject = createEventConfig.modalidad;
  const pricing = createEventConfig.precio.precio;
  const pricingStage = createEventConfig.precioEtapa;

  const classes = useStyles();

  function handleChange(value) {
    console.log(value);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "modalidadesInscripcion/" + subEventIndex + "/",
        value
      )
    );
  }
  function findValueByRoute(object, route, field) {
    const routeSplit = route.split("/");
    const fieldName = routeSplit[routeSplit.length - 1];
    let value = object;
    console.log(fieldName);
    console.log(value);
    for (var i = 0; i < routeSplit.length; i++) {
      value = value[routeSplit[i]] !== undefined ? value[routeSplit[i]] : "";
    }
    console.log(value);

    return value;
  }

  function renderDistanceConfig(data) {
    //    data = Object.values(data).sort((a, b) => a.order - b.order);

    let view = [];
    view.push(
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={8} sm={8} alignItems="center">
            <Typography gutterBottom variant={"h2"} component="h2">
              {data.descripcionModalidad}
            </Typography>
            <Typography gutterBottom variant={"h5"} component="h2">
              Distancia oficial:
              {data.modalidad >= 1
                ? data.modalidad
                : data.modalidad * 1000}{" "}
              {data.modalidad >= 1 ? "kilómetros" : "Metros"}{" "}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} alignItems="center">
            <Button
              color="primary"
              variant="contained"
              style={{ fontSize: "20px" }}
              onClick={() => setShowDetails(true)}
            >
              Abrir vista para edición
            </Button>
          </Grid>
        </Grid>

        <Paper>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={4} sm={4} alignItems="center">
              <Typography variant={"subtitle1"} component="h2" align="center">
                Precio :
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} alignItems="center">
              <Typography variant={"subtitle1"} component="h2" align="center">
                Folios:
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} alignItems="center">
              <Typography variant={"subtitle1"} component="h2" align="center">
                Status:
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} alignItems="center">
              <Typography
                gutterBottom
                variant={"h4"}
                component="h2"
                align="center"
              >
                {data.configModalidad.precio.preventa_1.precio_1}{" "}
                {registryConfigurationData.configuracion.moneda &&
                  Object.values(
                    registryConfigurationData.configuracion.moneda
                  ).filter((a) => a.tipo === "base")[0].codigo}
                {registryConfigurationData.configuracion.moneda === undefined &&
                  "MXN"}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} alignItems="center">
              <Typography
                gutterBottom
                variant={"h4"}
                component="h2"
                align="center"
              >
                {data.configModalidad.folios.desde} al{" "}
                {data.configModalidad.folios.hasta}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} alignItems="center">
              <Typography
                gutterBottom
                variant={"h4"}
                component="h2"
                align="center"
              >
                {data.configModalidad.folios.inscritos === 100000
                  ? "Agotado"
                  : "Disponible"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Typography
          color="primary"
          align="center"
          variant={"h3"}
          component="h2"
          style={{ marginTop: "10px" }}
        >
          Categorías
        </Typography>
      </Grid>
    );
    const ramas = data.ramaCat;
    for (var e in ramas) {
      const categorias = ramas[e].categorias;
      let cateogriesView = [];
      for (var f in categorias) {
        if (!categorias[f].hide) {
          cateogriesView.push(
            <Typography
              key={e + "-" + f + "title"}
              color="secondary"
              gutterBottom
              variant={"subtitle1"}
              component="h2"
              align="center"
            >
              {categorias[f].nombreCategoria} -{categorias[f].edadDesde}
              {categorias[f].edadHasta === 200
                ? " y mayores"
                : " a " + categorias[f].edadHasta + " años"}
            </Typography>
          );
        }
      }
      view.push(
        <Grid item xs={12} sm={6} alignItems="center">
          <Paper style={{ margin: "10px", padding: 5 }}>
            <Typography
              key={e + "modalidad"}
              color="secondary"
              gutterBottom
              variant={"h5"}
              component="h2"
              align="center"
            >
              {ramas[e].rama === "V" ? "Varonil" : "Femenil"}
              {cateogriesView}
            </Typography>
          </Paper>{" "}
        </Grid>
      );
    }

    return view;
  }

  if (!showDetails) {
    return (
      <Paper className={classes.paper}>
        <Grid container direction="row" alignItems="center">
          {eventSubEventsRegistry &&
            renderDistanceConfig(eventSubEventsRegistry[subEventIndex])}
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h2"} component="h2">
              {subEvent.descripcionModalidad}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setShowDetails(false)}
            >
              Ocultar Vista de edición
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
            {eventSubEventsRegistry &&
              eventSubEventsRegistry[subEventIndex] &&
              Object.values(createEventObject).map((field, index) => {
                const defaultValue = findValueByRoute(
                  eventSubEventsRegistry[subEventIndex],
                  field.fieldName
                );
                return (
                  <EditableField
                    key={index}
                    fieldObject={{
                      ...field,
                      defaultValue: defaultValue,
                    }}
                    onSubmit={handleChange}
                  />
                );
              })}
          </Grid>

          <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
            <Typography gutterBottom variant={"h2"} component="h2">
              Precios y preventas
            </Typography>
            <EditableField
              key={1000}
              fieldObject={{
                ...pricing,
                defaultValue: findValueByRoute(
                  eventSubEventsRegistry[subEventIndex],
                  pricing.fieldName
                ),
              }}
              onSubmit={handleChange}
            />

            {eventSubEventsRegistry &&
              eventSubEventsRegistry[subEventIndex] && (
                <PricingStages subEventIndex={subEventIndex} />
              )}
          </Grid>

          {Object.keys(subEvent.ramaCat).map((genderKey) => {
            return (
              <Grid item xs={12} sm={5} style={{ margin: "20px" }}>
                <Paper style={{ padding: "20px" }}>
                  <EventSpecificGender
                    subEventIndex={subEventIndex}
                    subEvent={subEvent}
                    gender={genderKey}
                  />
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Paper>
  );
}
